// DropdownComponent.js
import React from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";

const DropdownComponent = ({
  key,
  options,
  selectedKey,
  onChange,
  placeholder,
}) => {
  return (
    <Dropdown
      key={key || "drp_0"}
      placeholder={placeholder}
      options={options}
      selectedKey={selectedKey}
      onChange={onChange}
      styles={{ dropdown: { width: 300 } }}
    />
  );
};

export default DropdownComponent;
