import React from "react";
import { Stack, Text } from "@fluentui/react";
import Header from "../Layouts/Header";
import { AppSidebar } from "../Layouts/Sidebar";
import { useState } from "react";

export const Dashboard = () => {
  const [selectedOption, setSelectedOption] = useState("Home");

  return (
    <div>
      <div className="main-container">
        <div className="app-container">
          <div className="props">
            <h1
              style={{
                position: "fixed",
                display: "flex",
                marginTop: "1.7rem",
                left: "160px",
                fontSize: "20px",
                color: "#87CEEB",
              }}
            >
              {selectedOption}
            </h1>
          </div>
          <Stack
            verticalAlign="center"
            horizontalAlign="center"
            styles={{
              root: {
                height: "10vh",
                margin: "46px 24px 46px 24px;",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              },
            }}
          >
            {/* <Text style={{ fontSize: '25px' }}>Welcome To Exdion</Text> */}
            {/* <Breadcrumb selectedOption={selectedOption} /> */}
            {/* <button onClick={() => fetchSpreadsheetData()}>api call</button> */}
            {/* <button onClick={handleSave}>Save</button> */}
          </Stack>
        </div>
      </div>
    </div>
  );
};
