import React, { useEffect, useState } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GridDispatchedJobTableView from "./GridDispatchedJobTableView";

const DispatchedJobIdTransferData = () => {
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = React.useState(false);

  const getData = async () => {
    if (searchText && searchText?.length > 0) {
      let token = sessionStorage.getItem("token");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        let response = await axios.get(
          baseUrl +
            "/api/ProcedureData/GetDispatchjobid?jobId=" +
            searchText,
          {
            headers,
          }
        );
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };

  const handleSearch = () => {
    getData();
    handleDialogOpen()
  };

  const handleDialogClose = (event, reason) => {
    if (reason == "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{ width: "100%", padding: "0px", marginLeft: "5px" }}>
      <div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <TextField
            placeholder="Search JobId..."
            value={searchText}
            onChange={(e, newValue) => {
              setSearchText(newValue);
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={handleSearch}
            style={{
              marginLeft: "-50px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullScreen={true}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>TransferData-Jobid:{searchText}  </span>
              <span style={{ fontSize: "smaller", color: "indianred" }}>
                Click 'esc' key to close
              </span>
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>DispatchedJobIdTransferData
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <GridDispatchedJobTableView data={{ JobId: searchText }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );  
};

export default DispatchedJobIdTransferData;
