import ExcelJS from "exceljs";

// tablesData = [
//     {
//       data: [
//         {
//           Name: "John",
//           Age: 30,
//         },
//         {
//           Name: "Jane",
//           Age: 25,
//         },
//       ],
//     },
//     {data:[]},....
//   ];
export const ReportExcelExport = async (tablesData, fileName) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();

  // Add a new worksheet
  const worksheet = workbook.addWorksheet("Sheet1");

  tablesData.forEach((table, index) => {
    // Add table header
    const headerRow = worksheet.addRow(Object.keys(table.data[0]));

    // Apply styles to header
    headerRow.eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "8BADD4" }, // Yellow background
      };
      cell.font = {
        bold: true,
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = { wrapText: true }; // Enable text wrapping
    });

    // Add table data
    table.data.forEach((row) => {
      const excelRow = worksheet.addRow(Object.values(row));

      // Apply text wrapping and borders to each cell
      excelRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = { horizontal: "left", wrapText: true }; // Enable text wrapping
      });
    });

    if (index < tablesData.length - 1) {
      // Check if it's not the last table
      worksheet.addRow([]); // Empty row
      worksheet.addRow([]); // Another empty row
    }

    // Set column widths
    worksheet.columns.forEach((column) => {
      column.width = 30; // Adjust the width (you can set a max width if needed)
    });
  });

  // Write the workbook to a file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName ? fileName?.trim() + ".xlsx" : "tables.xlsx";
  a.click();
  window.URL.revokeObjectURL(url);
};
