import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import GridBackUpSection from "./GridBackUpSection";
import Box from "@mui/material/Box";
import ReportSection from "./ReportSection";
import ErrorLogSection from "./ErrorLogSection";
import ReProcessSection from "./ReProcessSection";
import DispatchedJobIdTransferData from "./DispatchedJobIdTransferData";
import JobConfigurations from "./JobConfigurations";
import J2JDataTransfer from "./J2Jdatatransfer";


const Report = (props) => {
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [activeTab, setActiveTab] = useState("table1");
  const [role, setRole] = useState(sessionStorage.getItem("role"));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {role.includes('report')? (
         <div>
         <div style={{ width: "100%" }}>
           <div className="app-container">
             <div style={{ padding: "5px", width: "95%" }}>
               {msgVisible && (
                 <div className="alert-container">
                   <div className={msgClass}>{msgText}</div>
                 </div>
               )}
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                 <Tabs
                   value={activeTab}
                   onChange={handleTabChange}
                   variant="scrollable"
                   scrollButtons
                   allowScrollButtonsMobile
                 >
                   <Tab
                     label="Reprocess section"
                     value="table1"
                     style={{ backgroundColor: "white !important" }}
                   />
                   <Tab
                     label="Error logs"
                     value="table2"
                     style={{ backgroundColor: "white !important" }}
                   />
                   <Tab
                     label="DELETION REPORT"
                     value="table3"
                     style={{ backgroundColor: "white !important" }}
                   />
                  
                   <Tab
                     label="BackUp Records"
                     value="table4"
                     style={{ backgroundColor: "white !important" }}
                   />
                  
                   <Tab
                     label="Transfer Data"
                     value="table5"
                     style={{ backgroundColor: "white !important" }}
                   />

                     <Tab
                      label="Job Configuration"
                      value="table6"
                      style={{ backgroundColor: "white !important" }}
                    />
                    <Tab
                      label="J2J data transfer"
                      value="table7"
                      style={{ backgroundColor: "white !important" }}
                    />
                 </Tabs>
               </Box>
               <div>
                 {activeTab == "table1" && <ReProcessSection  />}
                 {activeTab == "table2" && <ErrorLogSection />}
                 {activeTab == "table3" && (
                    <ReportSection activeTab={activeTab} />
                  )}
                 {activeTab == "table4" && <GridBackUpSection />}
                 {activeTab == "table5" && <DispatchedJobIdTransferData />}
                 {activeTab == "table6" && <JobConfigurations />}
                  {activeTab == "table7" && <J2JDataTransfer />}
               </div>
             </div>
           </div>
         </div>
       </div>
      ) :''}
    </>
  );
};

export default Report;
