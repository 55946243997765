import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppLayout from "../../Layouts/AppLayout";
import { authContext } from "./AuthManager";
import { processAndUpdateToken } from "../../Services/CommonFunctions";

const WrapperComponent = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (["/csrview","/unauthorizeduser","/accessdenied"]?.includes(location?.pathname?.toLowerCase())) {
      setIsAuthenticated(true);
    } else {
      authContext.handleWindowCallback();

      // Check if the app is being called back
      if (!authContext.isCallback(window.location.hash)) {
        // If not authenticated, login
        if (!authContext.getCachedUser()) {
          authContext.login();
        } else {
          GenerateToken();
        }
      } else {
        // if (!sessionStorage.getItem("token")) {
        //   GenerateToken();
        // } else {
        //   updateLoading();
        // }
        updateLoading();
      }
    }
  }, []);

  const GenerateToken = async () => {
    await processAndUpdateToken(
      sessionStorage.getItem("token"),
      !["/csrview", "/accessdenied", "unauthorizeduser"]?.includes(
        location?.pathname?.toLowerCase()
      )
        ? true
        : false
    );
    updateLoading();
  };

  const updateLoading = () => {
    const userData = authContext.getCachedUser();
    if (userData?.userName) {
      sessionStorage?.setItem("userName", userData?.userName);
    }
    console.log(userData);
    setIsAuthenticated(true);
  };

  return <div>{isAuthenticated ? <AppLayout /> : <p>Loading....</p>}</div>;
};
export default WrapperComponent;
