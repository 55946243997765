import React, { useEffect, useState } from "react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { DefaultButton } from "@fluentui/react/lib/Button";
import NewReport from "./ReprocessDatePicker";

const ReportSection = ({ activeTab }) => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const column = [
    {
      name: "Id",
      selector: (row, index) => index + 1,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "UserName",
      selector: (row) => {
        if (!row?.UserName) return "";
        const userName = row.UserName.split("@")[0];
        return userName.charAt(0).toUpperCase() + userName.slice(1);
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Status",
      selector: (row) => {
        const status = row?.Status;
        const color =
          status === "Processed"
            ? "lightGreen"
            : status === "Pending"
            ? "royalBlue"
            : status === "Failed"
            ? "red"
            : "black";
        return <div style={{ color }}>{status}</div>;
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Input Date",
      selector: (row) => {
        if (row.InputDate != null && row.InputDate != undefined) {
          const createdDate = new Date(row.InputDate).toDateString();
          return createdDate;
        } else {
          return "";
        }
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "CreatedOn Date",
      selector: (row) => {
        if (row.CreatedOn != null && row.CreatedOn != undefined) {
          const createdDate = new Date(row.CreatedOn).toDateString();
          return createdDate;
        } else {
          return "";
        }
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Export Excel",
      cell: (row) =>
        row?.Status === "Processed" ? (
          <DefaultButton
            onClick={(e) => {
              exportData(e, row);
            }}
            text={
              <span
                style={{ color: "black", fontSize: "10px", fontWeight: "bold" }}
              >
                Export
              </span>
            }
          />
        ) : null,
      style: { fontSize: "11px !important" },
    },
  ];

  const exportData = async (e, row) => {
    const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/Excel/ExcelDownloads?Id=` + row?.Id;

    try {
      const response = await axios({
        method: "Get",
        url: apiUrl,
        headers: headers,
        // data: {
        //   Id: row?.Id
        // },
        responseType: "blob",
      });
      if (response.status !== 200) {
        return "error";
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `DeletionReport_${new Date().toLocaleDateString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      sessionStorage.setItem("onUpdateClickCalled", false);
      return "success";
    } catch (error) {
      console.error("Error:", error);
      return "error";
    }
  };

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        baseUrl + "/api/ProcedureData/grid-reports",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        setJobInstance(response.data);
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };

  return (
    <div>
      <NewReport
      activeTab={activeTab}
        refresh={(e) => {
          getData();
        }}
      />
      <CommonDataTable key={1} column={column} data={jobInstance} />
    </div>
  );
};

export default ReportSection;
