import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppLayout from "./Layouts/AppLayout";
import WrapperComponent from './Components/AuthComponents/WrapperComponent';
import { isAdLogin } from './Services/Constants';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <div>
      {isAdLogin ? <WrapperComponent /> : <AppLayout />}
    </div>
  </BrowserRouter>
  // </React.StrictMode>
);
