import React, { useState, useEffect } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { GetDiscrepancyInfo, GetBrokerList } from "../Services/QuoteCompareService";
import DropdownComponent from "../Components/CommonComponents/DropDownComponent";
import CommonDataTable from "../Components/CommonDataTable";

const Discrepancy = () => {
    const [tableData, setTableData] = useState([]);
    const [brokerId, setBrokerId] = useState(null);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isSearch, setIsSearch] = React.useState(false);
    const [file, setFile] = useState(null);

    const column = [
        {
            name: "Id",
            selector: (row) => row.Id,
            sortable: true,
            width: "80px",
            style: { fontSize: "11px !important" },
        },
        {
            name: "Job Code",
            selector: (row) => row.JobCode,
            sortable: true,
            width: "270px",
            style: { fontSize: "11px !important" },
        },
        {
            name: "Job Status",
            selector: (row) => row.JobStatusName,
            sortable: true,
            width: "170px",
            style: { fontSize: "11px !important" },
        },
        {
            name: "Lob",
            selector: (row) => row.LOB,
            sortable: true,
            width: "300px",
            wrap: true,
            style: { fontSize: "11px !important" },
        },
        {
            name: "Lob Code",
            selector: (row) => row.LobCode,
            sortable: true,
            width: "100px",
            style: { fontSize: "11px !important" },
        },
        {
            name: "Uploaded By",
            selector: (row) => row.vchUser_Name,
            sortable: true,
            style: { fontSize: "11px !important" },
        },
        {
            name: "Action",
            cell: (row) => (
                <div>
                    {/* File Upload Button */}
                    <PrimaryButton
                        className="ref_button"
                        text={<span style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}>Upload</span>}
                        styles={{
                            rootHovered: {
                                backgroundColor: "#251ca8",
                            },
                        }}
                        onClick={() => document.getElementById("file-upload").click()} // Trigger file input on button click
                    />
                    <input
                        id="file-upload"
                        type="file"
                        style={{ display: "none" }} // Hide the default file input
                        onChange={handleFileChange}
                    />

                    {/* Download Button */}
                    <PrimaryButton
                    // onClick={() => rowItemExportCall()}
                        className="ref_button"
                        text={<span style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}>Download</span>}
                        styles={{
                            rootHovered: {
                                backgroundColor: "#251ca8",
                            },
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            // Check if the selected file is an .xlsx file
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            if (fileExtension === 'xlsx') {
                setFile(selectedFile);
                // Handle the file upload logic here
            } else {
                // Show an alert or handle the error if the file is not .xlsx
                alert("Please upload a valid .xlsx file.");
            }
        }
    };

    // Fetch broker list on component mount
    useEffect(() => {
        const fetchBrokerList = async () => {

            try {
                document.body.classList.add("loading-indicator");
                const brokerList = await GetBrokerList();
                if (brokerList && brokerList.length > 0) {
                    const dropDownData = brokerList.map((e) => ({
                        key: e?.BrokerId,
                        text: e?.VchBrokerName,
                    }));
                    setOptions(dropDownData);
                } else {
                    setOptions([]);
                }
            } catch (error) {
                console.error("Error fetching broker list:", error);
                setOptions([]);
                document.body.classList.remove("loading-indicator");
            }
            document.body.classList.remove("loading-indicator");
        };

        fetchBrokerList();
    }, []);

    const fetchJobInfo = async () => {
        if ((brokerId && brokerId !== 0) && searchText && searchText.length > 0) {
            document.body.classList.add("loading-indicator");
            try {
                const response = await GetDiscrepancyInfo(brokerId, searchText);
                setTableData(response);
            } catch (error) {
                console.error("Error fetching job info:", error);
                document.body.classList.remove("loading-indicator");
                setTableData([]);
            }
            document.body.classList.remove("loading-indicator");
        } else {
            setTableData([]);
        }
    };

    const handleDropdownChange = (event, option) => {
        setBrokerId(option.key);
    };

    const handleRefresh = () => {
        setBrokerId(null);  // Clear the dropdown selection
        setSearchText("");  // Clear the search text
        setIsSearch(false); // Reset the search status
        setTableData([]);   // Clear the table data
    };


    return (
        <div style={{ width: "100%" }}>
            <div className="app-container">
                <div style={{ padding: "10px", width: "95%" }}>
                    <p style={{ fontSize: "15px", color: "rgb(135, 206, 235)" }}>Discrepancy</p>
                    <div style={{ display: "flex" }}>
                        <DropdownComponent
                            key={"drop_1"}
                            options={options}
                            selectedKey={brokerId}
                            onChange={handleDropdownChange}
                            placeholder="Select a broker code"
                        />
                        <div style={{ display: "flex" }}>
                            <TextField
                                placeholder="Search Job..."
                                value={searchText}
                                onChange={(e, newValue) => {
                                    if (!newValue) {
                                        setIsSearch(false);
                                    }
                                    setSearchText(newValue);
                                }}
                                style={{ width: "250px", marginRight: "8px" }}
                            />
                            <PrimaryButton
                                text={<span style={{ color: "white", fontWeight: "bold" }}>Search</span>}
                                onClick={fetchJobInfo}
                            />
                        </div>
                        <PrimaryButton
                            text={<span style={{ color: "white", fontWeight: "bold" }}>Refresh</span>}
                            onClick={handleRefresh}
                            style={{ marginLeft: "20px", height: "32.1px", fontSize: "10px" }}
                        />
                    </div>
                    <br />
                    <div>
                        <CommonDataTable
                            key={"jData"}
                            column={column}
                            data={tableData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Discrepancy;
