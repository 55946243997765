import React, { useEffect, useState } from "react";
import Header from "./Header";
import { AppSidebar } from "./Sidebar";
import AppRoutes from "./AppRoutes";
import { useLocation, useNavigate } from "react-router-dom";

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState(
    sessionStorage.getItem("selectedOption") || "PolicyCheck"
  );

  useEffect(() => {
    sessionStorage.setItem("selectedOption", selectedOption);
  }, [selectedOption, location, navigate]);

  const canRenderHeaderSection = () => {
    if (
      !(
        location?.pathname?.toLowerCase() === "/csrview" ||
        location.pathname === "/xlpage" ||
        location.pathname === "/AccessDenied" ||
        location.pathname === "/UnAuthorizedUser" ||
        location.pathname === "/qcJob"
      )
    ) {
      return true;
    }
    return false;
  };

  const canRenderSideBar = () => {
    if (
      location?.pathname?.toLowerCase()  === "/csrview" ||
      location.pathname === "/AccessDenied" ||
      location?.pathname === "/UnAuthorizedUser"
    ) {
      return false;
    }
    return true;
  };
  return (
    <div>
      {canRenderHeaderSection() && (
        <div className="header-container">
          <div className="left-content">
            <div className="logo-container">
              <img
                src='ExdioniSTUDIOLogo.png'              
                alt="Exdion iSTUDIO Logo"
                className="header-logo"
              />
            </div>
            <div className="welcome-text">
              Welcome To Exdion
            </div>
          </div>
          <Header
            setSelectedOption={setSelectedOption}
          />
        </div>
      )}
      <div className="app-container">
        {canRenderSideBar() && (
          <div>
            <AppSidebar selectedOption={selectedOption} />
          </div>
        )}
        <div style={{ width: "100%" }}>
          <AppRoutes selectedOption={selectedOption} />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
