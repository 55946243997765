import React, { useEffect,useRef, useState } from "react";
import { processAndUpdateToken, structureColumns } from "../Services/CommonFunctions";
import axios from "axios";
import { TransferDialogComponent } from '../Services/dialogComponent';
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { PrimaryButton } from "@fluentui/react";
import { SimpleSnackbar } from '../Components/SnackBar';


const GridDispatchedJobTableView = (props) => {
  const container = useRef();
  const [JobId, setJobId] = useState(props?.data?.JobId);
  const [backUpData, SetBackUpData] = useState([]);
  const [columnKeys, SetColumnKeys] = useState({});
  const [canRender, SetCanRender] = useState(false);
  const [ msgText, setMsgText ] = useState( '' );
  const [ msgVisible, setMsgVisible ] = useState( false );
  const [ openDialog, setOpenDialog ] = useState( false );


  useEffect(() => {
      getData();
  }, [ props?.data?.JobId]);

  const getData = async () => {
    if (JobId && JobId?.length > 0) {
      let token = sessionStorage.getItem("token");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        let response = await axios.get(
          baseUrl +
            "/api/ProcedureData/GetDispatchjobid?jobId=" +
            JobId,
          {
            headers,
          }
        );
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
        if (response?.data) {
            const tables = [
                ...response?.data.map((e) => {
                  return e?.Tablename;
                }),
              ];
              let slicedResponse = response?.data;
              slicedResponse.forEach(ary => {
                ary.TemplateData.forEach(item => {
                   Object.keys(item).forEach(key => {
                    if(item[key] === null){
                      delete item[key];
                    }
                   })
                })

              })
              SetBackUpData(slicedResponse);
              const columnObject = {};
              tables.forEach((key) => {
                const column = structureColumns(key, response.data);
                columnObject[key] = column;
              });
              SetColumnKeys(columnObject);
              SetCanRender(true);
        } else {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };
  
  const remainderDailog = async (JobId) => {
    if (JobId)
      {
          setOpenDialog( true );
          setMsgText( 'Are You Sure You Want to Transfer the Data' );
          setTimeout( () => {
              setMsgVisible( false );
              setMsgText( '' );
          }, 4500 );
          { openDialog && <TransferDialogComponent isOpen={ openDialog } onClose={ ( e ) => handleDialogClose( e ) } message={ msgText } /> }
      }
  }

  const transformDataForExport = async (JobId) => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token); 
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
  
      let response = await axios.post(
        baseUrl + "/api/ProcedureData/CsrTransferTable?jobId=" + JobId,
        {}, 
        { headers: headers }
      );
      
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        return response.data;
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };
  

  const handleDialogClose = async ( e ) => {
    if ( e == false )
    {
        setOpenDialog( e );
    }else if( e == true){
      setOpenDialog(false)
      await transformDataForExport(JobId);
      container.current.showSnackbar('Transfered Data successfully',"info", true );
    }
   
  } 

  return (
    <div>
       <PrimaryButton
        text={
          <span style={{ color: "white", fontWeight: "bold" }}>Transfer</span>
        }
      
        onClick={() => {
          remainderDailog(JobId);
        }}
        style={{
          height: "32.1px",
          fontSize: "10px",
        }}
        disabled={!backUpData || backUpData.length === 0}
      />
      {backUpData &&
        canRender &&
        backUpData?.length > 0 &&
        backUpData?.map((item, index) => (
          <>
           <CommonDataTable
              key={index}
              column={columnKeys[ item?.Tablename]} 
              data={ item?.TemplateData || []}
            />
            <br />
          </>
        ))}
         { openDialog && <TransferDialogComponent isOpen={ openDialog } onClose={ ( e ) => handleDialogClose( e ) } message={ msgText } /> }
         <SimpleSnackbar ref={ container } />
    </div>
  );
};

export default GridDispatchedJobTableView;
