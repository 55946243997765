import React, { useState } from "react";
import CommonDataTable from "./CommonDataTable";
import { TextField, PrimaryButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";

const ChecklistErrorLog = () => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);

  const column = [
    {
      name: "Error Message",
      selector: (row) => row,
      sortable: true,
      style: { fontSize: "11px !important" },
      //   width: "100px",
    },
  ];

  const getData = async () => {
    if (searchText && searchText?.length > 0) {
      let token = sessionStorage.getItem("token");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        let response = {};
        response = await axios.get(
          baseUrl +
            "/api/Defaultdatum/GetJobGenerationErrors?jobId=" +
            searchText,
          {
            headers,
          }
        );
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
        if (response?.data) {
          setJobInstance(response.data);
        } else {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };

  return (
    <div style={{ width: "100%", marginTop: "5px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <TextField
          placeholder="Search JobId..."
          value={searchText}
          onChange={(e, newValue) => {
            setSearchText(newValue);
          }}
          style={{
            width: "250px",
            marginRight: "8px",
          }}
        />
        <PrimaryButton
          text={
            <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
          }
          onClick={() => {
            getData();
          }}
          style={{
            marginLeft: "-50px",
            height: "32.1px",
            fontSize: "10px",
          }}
        />
      </div>
      <br />
      <div>
        <CommonDataTable column={column} data={jobInstance} />
      </div>
    </div>
  );
};

export default ChecklistErrorLog;
