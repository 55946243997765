import React, { useEffect, useRef } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import App from "../App";
import Home from "../Components/Home";
import { Dashboard } from "../Components/DashBoard";
import { LandingPage } from "../Components/LandingPage";
import { baseData } from "../Services/Constants";
import AllJob from "../Components/AllJob";
import UnAuthorizedUser from "../Components/UnAuthorizedUser";
import AccessRequired from "../Components/AccessRequired";
import DispatchedJobScreen from "../Components/DispatchedJobScreen";
import Report from "../Components/Report";
import ChecklistErrorLog from "../Components/ChecklistErrorLog";
import CsrReport from "../Components/CsrReport";
import DeletionReport from "../Components/DeletionReport";
import QuoteCompare from "../Components/QuoteCompare/QuoteCompare";
import QCDataRenderer from "../Components/QuoteCompare/QCDataRenderer";
import Discrepancy from "../Components/Discrepancy";

const AppRoutes = ({ selectedOption }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const qcPaths = ["/qcjob", "/quotecompare", "/unauthorizeduser"];
    const token = sessionStorage.getItem("token");
    const sessionUserName = location.search.split('=')[1];

  }, [selectedOption]);

  return (
    <>
      <Routes>
        {selectedOption === "PolicyCheck" ? <Route path="/" element={<Navigate to="/job" />} /> : <Route path="/" element={<Navigate to="/quoteCompare" />} />}
        <Route path="/UnAuthorizedUser" element={<UnAuthorizedUser />} />
        <Route path="/DispatchedJob" element={<DispatchedJobScreen />} />
        <Route path="/AccessDenied" element={<AccessRequired />} />
        <Route path="/xlpage" element={<App baseData={baseData} />} />
        <Route path="/csrView" element={<App baseData={baseData} />} />

        {selectedOption === "QuoteCompare" && (
          <>
            <Route path="/quoteCompare" element={<QuoteCompare />} />
            <Route path="/qcJob" element={<QCDataRenderer />} />

          </>
        )}

        {selectedOption === "PolicyCheck" && (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/job" element={<Home />} />
            <Route path="/AllJob" element={<AllJob />} />
            <Route path="/checkListError" element={<ChecklistErrorLog />} />

            {/* Role-based routes for "PolicyCheck" */}

            {sessionStorage.getItem("role") &&
              sessionStorage.getItem("role").includes("report") && (
                <Route path="/AdminAccess" element={<Report />} />
              )}
            {sessionStorage.getItem("roles") &&
              sessionStorage.getItem("roles").includes("CsrReport") && (
                <Route path="/CSRReportPanel" element={<CsrReport />} />
              )}
            {sessionStorage.getItem("roles") &&
              (
                <Route path="/Discrepancy" element={<Discrepancy />} />
              )}
            {sessionStorage.getItem("role") &&
              sessionStorage.getItem("role").includes("DeletionReport") && (
                <Route path="/DeletionReportPanel" element={<DeletionReport />} />
              )}
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
