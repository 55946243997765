import React, { useState } from "react";
import { baseUrl } from "../Services/Constants";
import axios from "axios";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { DatePicker } from "@fluentui/react";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { IIconProps } from "office-ui-fabric-react/lib/Icon";
import { format } from "date-fns";

const NewReport = ({ activeTab , refresh}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [token, setToken] = useState(sessionStorage.getItem("token"));

  const onDateChange = (date) => {
    if (date) {
      const formattedDate = format(date, "M/d/yyyy");
      setSelectedDate(formattedDate);
    }
  };

  const postApiCallForDatepicker = async (selectedDate) => {
    document.body.classList.add("loading-indicator");
    let Token = await processAndUpdateToken(token);
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/ProcedureData/grid-reports`;

    axios({
      method: "POST",
      url: apiUrl,
      headers: headers,
      data: {
        InputDate: selectedDate,
        Status: "Pending",
        UserName: sessionStorage.getItem("userName"),
      },
    })
      .then((response) => {
        if (response.status !== 201) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        refresh();
        setSelectedDate(null);
        return response.data;
      })
      .then((data) => {
        if (data?.status == 400) {
          setMsgVisible(true);
          setMsgClass("alert error");
          setTimeout(() => {
            setMsgVisible(false);
            setMsgText("");
          }, 3500);
        }
      })
      .finally(() => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const postAmsApiCallForDatepicker = async (selectedDate) => {
    document.body.classList.add("loading-indicator");
    let Token = await processAndUpdateToken(token);
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/ProcedureData/grid-AddAMSreports`;

    axios({
      method: "POST",
      url: apiUrl,
      headers: headers,
      data: {
        InputDate: selectedDate,
        Status: "Pending",
        UserName: sessionStorage.getItem("userName"),
      },
    })
      .then((response) => {
        if (response.status !== 201) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        refresh();
        setSelectedDate(null);
        return response.data;
      })
      .then((data) => {
        if (data?.status == 400) {
          setMsgVisible(true);
          setMsgClass("alert error");
          setTimeout(() => {
            setMsgVisible(false);
            setMsgText("");
          }, 3500);
        }
      })
      .finally(() => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const handleButtonClick = (selectedDate) => {
    if (activeTab === "table3") {
      postApiCallForDatepicker(selectedDate);
    }
    //  else if (activeTab === "table4") {
    //   postAmsApiCallForDatepicker(selectedDate);
    // }
  };

  const refreshIcon = { iconName: "Refresh" };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            style={{ width: "200px", margin: "13px 10px 9px 6px" }}
            placeholder="Select a date..."
            onSelectDate={onDateChange}
          />
          {selectedDate && (
            <PrimaryButton
              onClick={() => handleButtonClick(selectedDate)}
            >
              Generate
            </PrimaryButton>
          )}
        </div>
        <IconButton
          iconProps={refreshIcon}
          title="Refresh"
          ariaLabel="Refresh"
          onClick={() => {
            refresh();
          }}
          style={{
            color: "black",
            margin: "8px 20px 8px 20px",
            boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        />
      </div>
    </>
  );
};

export default NewReport;
