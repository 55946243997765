import React, { useState } from "react";
import CommonDataTable from "./CommonDataTable";
import { TextField, PrimaryButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";

const J2JDataTransfer = () => {
    const [FromJobId, setFromJobId] = useState("");
    const [ToJobId, setToJobId] = useState("");
    const [jobInstance, setJobInstance] = useState([]);
    const column = [
        {
            name: "Message",
            selector: (row) => row.Message, // Access the "Message" property
            sortable: true,
            style: { fontSize: "11px !important" },
            width: "200px",
        },
    ];
    const getData = async (FromJobId, ToJobId) => {
        if (FromJobId && ToJobId) {
            document.body.classList.add("loading-indicator");
            let token = sessionStorage.getItem("token");
            const updatedToken = await processAndUpdateToken(token);
            const headers = {
                Authorization: `Bearer ${updatedToken}`,
                "Content-Type": "application/json",
            };
            try {
                const extent = `?FromJobId=${FromJobId}&ToJobId=${ToJobId}`;
                const URL = `${baseUrl}/api/ProcedureData/transfer-checklist-data${extent}`;
                const response = await axios.post(URL, null, { headers });


                if (response.status === 200) {
                    const successMessage = response.data.Message || "Data Success";
                    setJobInstance([{ Message: successMessage }]);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } catch (error) {
            } finally {
                document.body.classList.remove("loading-indicator");
            }
        }
    };
    return (
        <div style={{ width: "100%", marginTop: "5px", marginLeft: "5px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <label style={{ marginBottom: "2px", fontSize: "14px", }}>
                    From JobId :
                </label>
                <TextField
                    placeholder="From JobId..."
                    value={FromJobId}
                    onChange={(e, newValue) => {
                        setFromJobId(newValue);
                    }}
                    style={{
                        width: "200px",
                        marginRight: "8px",
                    }}
                />
                <label style={{ marginBottom: "2px", fontSize: "14px"}}>
                    To JobId :
                </label>
                <TextField
                    placeholder="To JobId..."
                    value={ToJobId}
                    onChange={(e, newValue) => {
                        setToJobId(newValue);
                    }}
                    style={{
                        width: "200px",
                        marginRight: "24px", // Increase spacing here
                    }}
                />
                <PrimaryButton
                    text={
                        <span style={{ color: "white", fontWeight: "bold" }}>
                            Transfer
                        </span>
                    }
                    onClick={() => {
                        getData(FromJobId, ToJobId)
                    }}
                    style={{
                        height: "32.1px",
                        fontSize: "10px",
                    }}
                />
            </div>
            <br />
            <div>
                <CommonDataTable column={column} data={jobInstance} />
            </div>
        </div>
    );
};

export default J2JDataTransfer;
