import { AdalConfig, AuthenticationContext, runWithAdal } from "react-adal";

const DO_NOT_LOGIN = false;

const adalConfig = {
  tenant: "ca7621e7-a15f-4dc1-9515-5cbd8ac365b8",
  clientId: "413ed7ce-1b47-4ee9-9820-a05147c0f58c",
  endpoints: {
    api: "413ed7ce-1b47-4ee9-9820-a05147c0f58c",
  },
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  cacheLocation: "localStorage" || "sessionStorage",
};

const authContext = new AuthenticationContext(adalConfig);

export const isAuthenticated = () => {
  const authResponse = !authContext.getCachedUser();
  return authResponse;
};

export const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  const loginSite = "azuread";
  const baseUrl = window.location.origin.includes("localhost:")
    ? "http://localhost:3000"
    : window.location.origin;
  window.location.href =
    loginSite.toLowerCase() == "azuread"
      ? "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" +
        baseUrl
      : "/login";
};

const decodeToken = (token) => {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
};

export { authContext };
