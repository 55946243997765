import { useEffect, useState } from "react";
import { baseUrl } from "../Services/Constants";
import { Link } from "react-router-dom";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Grid, Tooltip } from "@mui/material";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { AppSidebar } from "../Layouts/Sidebar";
import Header from "../Layouts/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import { processAndUpdateToken } from "../Services/CommonFunctions";

const Home = (props) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [jobId, setJobId] = useState();
  const [jobInstance, setJobInstance] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState("My Jobs");
  const [selectOption, setSelectOption] = useState("Welcome To Exdion");
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [token, setToken] = useState(null);
  const [tableNames, setTableNames] = useState([]);

  useEffect(() => {
    TokenRender();
    setFilteredData(filteredData);
  }, []);

  const TokenRender = async () => {
    const url = new URL(window.location.href);
    const userName =
      sessionStorage.getItem("userName") || url?.searchParams.get("user");
    const needUserValidation = userName != null && userName != undefined;
    if (!needUserValidation) {
      window.location.href = "/UnAuthorizedUser";
      return;
    }
    const response = await processAndUpdateToken(
      null,
      needUserValidation,
      userName
    );
    if (response) {
      setToken(response);
      GetallJobinstance(response);
      sessionStorage.setItem("token", response);
    }
  };

  const GetallJobinstance = (token) => {
    if (token.length >= 40) {
      document.body.classList.add("loading-indicator");
      var token = token;
      setToken(token);
      const headers = {
        Authorization: `Bearer ${token}`, // Pass the token dynamically
      };
      axios
        .get(baseUrl + "/api/Defaultdatum/GetallJobinstance", { headers })
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.data;
        })
        .then((data) => {
          setJobInstance(data);
        })
        .catch((error) => {
          setMsgVisible(true);
          setMsgClass("alert error");
          setMsgText(`Error fetching data: ${error}`);
          setTimeout(() => {
            setMsgVisible(false);
            setMsgText("");
          }, 3500);
          setJobInstance([]);
        })
        .finally(() => {
          document.body.classList.remove("loading-indicator");
        });
    }
  };

  const RegenerateChecklist = (row) => {
    document.body.classList.add("loading-indicator");
    var token = sessionStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const jobId = row.Jobid;

    axios
      .post(
        baseUrl + "/api/ProcedureData/RegeneratedChecklist",
        { jobId },
        { headers }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.data;
      })
      .then((data) => {
        if (data?.status == 400) {
          setMsgVisible(true);
          setMsgClass("alert error");
          setMsgText(data?.title);
          setTimeout(() => {
            setMsgVisible(false);
            setMsgText("");
          }, 3500);
        } else {
          const updatedJobInstance = jobInstance.filter(
            (job) => job.Jobid !== jobId
          );
          setJobInstance(updatedJobInstance);
          if (searchClicked) {
            setFilteredData([]);
          }
          setMsgVisible(true);
          setMsgClass("alert success");
          setMsgText("Regenerated Successfully");
          setTimeout(() => {
            setMsgVisible(false);
            setMsgText("");
          }, 3500);
        }
        setJobInstance(jobInstance);
      })
      .finally(() => {
        document.body.classList.remove("loading-indicator");
      });
  };

  const indexOfLastItem = (currentPage - 1) * itemsPerPage + 1;

  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = jobInstance.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(jobInstance.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevPage = () => {
    handlePageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevPage();
    } else if (event.key === "ArrowRight") {
      handleNextPage();
    }
  };

  const maxPageNumbersToShow = 10; // Adjust the number of page numbers to show

  const rowsPerPageOptions = [10, 20, 30, 40, 50, 100]; // Customize the available options as needed

  const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
    setCurrentPage(currentPage);
    setItemsPerPage(newRowsPerPage);
  };

  const renderPageNumbers = () => {
    if (totalPages <= maxPageNumbersToShow) {
      return Array.from({ length: totalPages }, (_, index) =>
        renderPageNumber(index + 1)
      );
    }

    const middlePage = Math.floor(maxPageNumbersToShow / 2) + 1;
    const startPage = Math.max(currentPage - middlePage + 1, 1);
    const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    const pages = [];
    if (startPage > 1) {
      pages.push(renderPageNumber(1));
      if (startPage > 2) {
        pages.push(<span key="ellipsis-start">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(renderPageNumber(i));
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="ellipsis-end">...</span>);
      }
      pages.push(renderPageNumber(totalPages));
    }

    return pages;
  };

  const renderPageNumber = (pageNumber) => (
    <span
      key={pageNumber}
      className={`pagination-number ${
        currentPage === pageNumber ? "active" : ""
      }`}
      onClick={() => handlePageChange(pageNumber)}
    >
      {pageNumber}
    </span>
  );

  const handlePageChanges = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = () => {
    const filtered = jobInstance
      .map((item) => {
        const jobId = item.Jobid;
        const searchTextLower = searchText.toLowerCase().trim();

        if (jobId.includes(searchTextLower)) {
          return item;
        }
        return null;
      })
      .filter(Boolean);

    setFilteredData(filtered);
    setSearchClicked(true);
  };

  const redirectIntoJob = (jobId) => {
    if (jobId) {
      window.location.href = `/xlpage?jobId=${jobId}`;
    }
  };

  const column = [
    {
      name: "S.No",
      cell: (row, index) => indexOfLastItem + index,
      sortable: true,
    },
    {
      name: "Id",
      cell: (row) => row?.Id,
      sortable: true,
    },
    {
      name: "Job Id",
      cell: (row) => (
        <Link
          // to={ `/xlpage?jobId=${ row.Jobid }` }
          onClick={() => redirectIntoJob(row.Jobid)}
        >
          {row.Jobid}
        </Link>
      ),
      sortable: true,
    },
    {
      name: "Regenerate Checklist",
      cell: (row) => (
        <DefaultButton
          className="ref_button"
          text={
            <span
              style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}
            >
              Regenerate
            </span>
          }
          onClick={() => RegenerateChecklist(row)}
          styles={{
            rootHovered: {
              backgroundColor: "#251ca8",
            },
          }}
        />
      ),
    },
  ];

  return (
    <div style={{ width: "95%", padding: "10px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span
          style={{
            fontSize: "20px",
            color: "#87CEEB",
          }}
        >
          {selectedOption}
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginLeft: "5px",
          }}
        >
          <TextField
            placeholder="Search JobId..."
            value={searchText}
            onChange={(e, newValue) => {
              setSearchText(newValue);

              // Check if the search text is empty
              if (newValue === "") {
                // If empty, set jobInstance to the original data
                setJobInstance(jobInstance);
                setFilteredData([]);

                setSearchClicked(false);
              }
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={handleSearch}
            style={{
              marginLeft: "-80px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
      </div>
      <br />
      {/* <Grid container style={{ display: 'flex', position: 'fixed', justifyContent: 'flex-end', margin: '14px 32px 14px -32px' }}> */}
      {/* <Grid
        lg={12}
        item
        container
        style={{ marginTop: "1.6%", position: "fixed" }}
        direction="row"
      >
        <Grid item lg={9} sm={7} />
        <Grid item lg={2.6}>
          <TextField
            placeholder="Search JobId..."
            value={searchText}
            onChange={(e, newValue) => {
              setSearchText(newValue);

              // Check if the search text is empty
              if (newValue === "") {
                // If empty, set jobInstance to the original data
                setJobInstance(jobInstance);
                setFilteredData([]);

                setSearchClicked(false);
              }
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
        </Grid>
        <Grid item>
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={handleSearch}
            style={{
              marginLeft: "-80px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </Grid>
      </Grid> */}

      <div>
        {msgVisible && (
          <div className="alert-container">
            <div className={msgClass}>{msgText}</div>
          </div>
        )}

        {/* <div className="props">
          <h1
            style={{
              position: "fixed",
              display: "flex",
              marginTop: "-5rem",
              left: "120px",
              fontSize: "20px",
              color: "#87CEEB",
            }}
          >
            {selectedOption}
          </h1>
        </div> */}
        {jobInstance.length > 0 ? (
          <DataTable
            columns={column}
            data={searchClicked ? filteredData : jobInstance}
            fixedHeader
            pagination
            paginationPerPage={itemsPerPage}
            paginationRowsPerPageOptions={rowsPerPageOptions}
            paginationRowsPerPageText="Rows per page:"
            paginationServer={false}
            paginationTotalRows={jobInstance.length}
            paginationComponentOptions={{
              page: currentPage,
              rowsPerPage: itemsPerPage,
              serverSide: true,
              showFirstLastPageButtons: false,
            }}
            customStyles={{
              headRow: {
                style: {
                  background: "gainsboro", // Set your desired background color here
                },
              },
              pagination: {
                style: {
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  maxHeight: "400px",
                  background: "whitesmoke",
                  padding: "10px",
                },
              },
            }}
            onChangePage={(page) => handlePageChanges(page)}
            onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
              handleRowsPerPageChange(currentRowsPerPage, currentPage)
            }
          />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                gap: "23rem",
                padding: "20px",
                background: "gainsboro",
                fontSize: "12px",
              }}
            >
              {column.map((col, index) => (
                <div key={index}>{col.name}</div>
              ))}
            </div>
            <p style={{ textAlign: "center" }}>
              There are no records to display
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
