import React, { useEffect, useState } from "react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { TextField, PrimaryButton } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";

const CsrPendingReport = ({ activeTab }) => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [jobInstanceFiltered, setJobInstanceFiltered] = useState([]);


  useEffect(() => {
    getData();
  }, []);

  const column = [
    {
      name: "Id",
      selector: (row, index) => index + 1,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Job id",
      selector: (row) => {
        if (!row?.JobId) return "";
        const userName = row.JobId;
        return userName;
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "UserName",
      selector: (row) => {
        if (!row?.User) return "";
        const User = row.User.split("@")[0];
        return User.charAt(0).toUpperCase() + User.slice(1);
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Export Excel",
      cell: (row) =>
        <DefaultButton
          onClick={(e) => {
            exportData(e, row);
          }}
          text={
            <span
              style={{ color: "black", fontSize: "10px", fontWeight: "bold" }}
            >
              Export
            </span>
          }
        />
    },
  ];

  const filterData = () => {
    if (searchText && searchText?.length > 0) {
      const filteredData = jobInstance?.filter((f) =>
        f?.JobId?.toLowerCase()?.trim()?.includes(searchText?.trim())
      );
      setJobInstanceFiltered(filteredData);
      setIsSearch(true);
    } else {
      setJobInstanceFiltered([]);
      setIsSearch(false);
    }
  };

  const exportData = async (e, row) => {
    const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/Excel/CsrExportsaveExcelDownloads/CsrExportSaveExportExcel?Id=` + row?.Id;
    try {
      const response = await axios({
        method: "Get",
        url: apiUrl,
        headers: headers,
        responseType: "blob",
      });
      if (response.status !== 200) {
        return "error";
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `CsrSaveReport${new Date().toLocaleDateString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      sessionStorage.setItem("onUpdateClickCalled", false);
      return "success";
    } catch (error) {
      console.error("Error:", error);
      return "error";
    }
  };

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        baseUrl + "/api/ProcedureData/grid-CsrExportreports",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        setJobInstance(response.data);
        setJobInstanceFiltered([]);
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };

  return (
    <div style={{ width: "100%", paddingTop: "5px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <TextField
          placeholder="Search JobId..."
          value={searchText}
          onChange={(e, newValue) => {
            setSearchText(newValue);
          }}
          style={{
            width: "250px",
            marginRight: "8px",
          }}
        />
        <PrimaryButton
          text={
            <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
          }
          onClick={() => {
            filterData();
          }}
          style={{
            marginLeft: "-50px",
            height: "32.1px",
            fontSize: "10px",
          }}
        />
      </div>
      <br />
      <CommonDataTable key={1} column={column}
        data={isSearch ? jobInstanceFiltered : jobInstance} />
    </div>
  );
};

export default CsrPendingReport;
