import React, { useEffect, useState } from "react";
import { Navigate} from "react-router-dom";
import { Grid, Typography, Switch, FormControlLabel, Button, Avatar, Popover,List,ListItem,ListItemButton,ListItemText } from '@mui/material';
import { TooltipHost, Icon } from '@fluentui/react';
import { Stack } from "office-ui-fabric-react";
// import jwt_decode from "jwt-decode";
import { logout ,authContext } from "../Components/AuthComponents/AuthManager";

export const Header = ({ selectedOption, setSelectedOption }) => {
    const [toggle, setToggle] = useState("PolicyCheck");
    const [avatarEl, setAvatarEl] = React.useState(null);
    const [userName,setUserName] = useState(authContext?.getCachedUser()?.userName || "");
    
    const [invisible, setInvisible] = React.useState(false);  

    useEffect(() => {
        const toggleLocalData = sessionStorage.getItem("selectedOption");
        if (toggleLocalData) {
            setToggle(toggleLocalData);  
            setSelectedOption(toggleLocalData); 
        }
    }, [setSelectedOption]);

    const handleAvatarClick = (e) => {
        setAvatarEl(e.currentTarget);
      };

      const handleAvatarClose = () => {
        setAvatarEl(null);
      };

    const handleToggleChange = (event) => {
        const newOption = event?.target?.checked ? "QuoteCompare" : "PolicyCheck";
        setToggle(newOption);
        setSelectedOption(newOption);
        sessionStorage.setItem("selectedOption", newOption);
    };

  // const first = username.charAt(0).toUpperCase();
  // const last = username.charAt(username.length - 1).toUpperCase();

    // const first = username.charAt(0).toUpperCase();
    // const last = username.charAt(username.length - 1).toUpperCase();

    const open = Boolean(avatarEl);
    const id = open ? "simpe-popover" : undefined;

    return (
        <div className="header">
            <Grid container alignItems="center" justifyContent="space-between" style={{alignItems:'flex-start'}}>
                <Grid item>
                    <h2>{selectedOption}</h2>
                </Grid>
                <Grid item style={{marginRight:'20px'}}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        {["gokul_n@exdion.com","guruprasad_sb@exdion.dev"]?.includes(userName?.toLowerCase()) && (<><Grid item>
                            <Typography
                                style={{
                                    display: 'flex',
                                    fontWeight: toggle === "PolicyCheck" ? "bold" : "normal",
                                    // color: toggle === "PolicyCheck" ? "black" : "#8080801a",
                                    marginRight: 16,
                                }}
                            >
                                PolicyCheck
                            </Typography>
                        </Grid><Grid item>
                                <FormControlLabel
                                    control={<Switch
                                        checked={toggle === "QuoteCompare"}
                                        onChange={handleToggleChange}
                                        name="toggle"
                                        color="primary" />}
                                    label="" />
                            </Grid><Grid item>
                                <Typography
                                    style={{
                                        display: 'flex',
                                        margin: '0px 10px 0px 0px',
                                        marginLeft: -20,
                                        fontWeight: toggle === "QuoteCompare" ? "bold" : "normal",
                                        // color: toggle === "QuoteCompare" ? "black" : "#8080801a",
                                    }}
                                >
                                    QuoteCompare
                                </Typography>
                            </Grid></>)}
                        <Grid item style={{marginTop:'-10px'}}>
                            <Button aria-describedby={id} onClick={handleAvatarClick} title={userName || ""}>
                                <Avatar style={{width:'30px',height:'30px'}}>{userName[0] || ""}</Avatar>
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={avatarEl}
                                onClose={handleAvatarClose}
                                anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left"
                                }}
                            >
                                <List disablePadding>
                                {/* <ListItem disablePadding>
                                    <ListItemButton>
                                    <ListItemText primary="Profile" />
                                    </ListItemButton>
                                </ListItem> */}
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {logout();}}>
                                    <ListItemText primary="Log out" />
                                    </ListItemButton>
                                </ListItem>
                                </List>
                            </Popover>
                            {/* <Button onClick={() => {logout();}}>LogOut</Button> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
}

export default Header;
