import React, { useState, useRef, useEffect } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Link } from "react-router-dom";
import { GetJobInfo, GetBrokerList, GetJobQCData } from "../../Services/QuoteCompareService";
import { processAndUpdateToken } from "../../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../../Services/Constants";
import DropdownComponent from "../CommonComponents/DropDownComponent";
import CommonDataTable from "../CommonDataTable";
import { SimpleSnackbar } from "../SnackBar";

const QuoteCompare = () => {
  const container = useRef();
  const [tableData, setTableData] = useState([]);
  const [tableDataFiltered, setTableDataFiltered] = useState([]);
  const [brokerId, setBrokerId] = useState(null);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = React.useState(false);
  const [file, setFile] = useState(null);
  const token = sessionStorage.getItem('token');


  const column = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
      width: "80px",
      style: { fontSize: "11px !important" },
    },
    {
      name: "Job Code",
      selector: (row) => row.JobCode,
      cell: (row) => (
        <Link
          // to={`/qcJob?jobId=${row.JobCode}&id=${row.Id}&brokerId=${row?.BrokerID}`}
          onClick={() => redirectIntoJob(row.JobCode, row?.Id, row?.BrokerID)}
        >
          {row.JobCode}
        </Link>
      ),
      sortable: true,
      width: "230px",
      style: { fontSize: "11px !important", flexWrap: 'wrap' },
    },
    {
      name: "Job Status",
      selector: (row) => row.JobStatusName,
      sortable: true,
      width: "170px",
      style: { fontSize: "11px !important" },
    },
    {
      name: "Lob",
      selector: (row) => row.LOB,
      sortable: true,
      width: "200px",
      wrap: true,
      style: { fontSize: "11px !important", flexWrap: 'wrap' },
    },
    {
      name: "Lob Code",
      selector: (row) => row.LobCode,
      sortable: true,
      width: "100px",
      style: { fontSize: "11px !important" },
    },
    {
      name: "Uploaded By",
      selector: (row) => row.vchUser_Name,
      sortable: true,
      width: "200px",
      style: { fontSize: "11px !important", flexWrap: 'wrap' },
    },
    {
      name: "Action",
      width: "350px",
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          <PrimaryButton
            className="ref_button"
            text={<span style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}>Upload Discrepancy</span>}
            style={{
              height: '25px',
              marginBottom: '2px'
            }}
            styles={{
              rootHovered: {
                backgroundColor: "#251ca8",
              },
              root: {
                marginRight: "10px",
              },
            }}
            onClick={() => document.getElementById(`file-upload-${row?.Id}`).click()}
          />
          <input
            id={`file-upload-${row.Id}`}   // unique id's
            type="file"
            accept=".xlsx" // Restrict to .xlsx files only
            style={{ display: "none" }}
            onChange={(event) => handleFileChange(event, row?.BrokerID, row?.Id)}
          />

          <PrimaryButton
            className="ref_button"
            text={<span style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}>Download Discrepancy</span>}
            style={{ height: '25px' }}
            styles={{
              rootHovered: {
                backgroundColor: "#251ca8",
              }
            }}
            onClick={() => rowItemExportCall(row?.Id, row?.BrokerID, row?.JobCode)}
          />
        </div>
      ),
    },
  ];

  const rowItemExportCall = async (id, BrokerId, jobId) => {
    try {
      const jobQCDataStateUpdate = await GetJobQCData(id, BrokerId);
      const resultDataSet = [];
      const groupedByQuestion = jobQCDataStateUpdate?.reduce((acc, item) => {
        if (!acc[item?.GroupID]) {
          acc[item?.GroupID] = [];
        }

        if (!acc[item?.GroupID][item?.ChecklistQuestion]) {
          acc[item?.GroupID][item?.ChecklistQuestion] = [];
        }

        if (!acc[item?.GroupID][item?.ChecklistQuestion][item?.DocumentName]) {
          acc[item?.GroupID][item?.ChecklistQuestion][item?.DocumentName] = {
            ExtractedValue: item?.ExtractedValue,
            PageNumber: item?.PageNum,
            MatchScore: item?.MatchScore
          };
        }
        return acc;
      }, {});

      for (const [groupId, groupData] of Object.entries(groupedByQuestion)) {
        for (const [question, docs] of Object.entries(groupData)) {
          const combinedObject = { Question: question };

          let pageIdx = 1;
          for (const [docName, docDetails] of Object.entries(docs)) {
            combinedObject[docName] = docDetails?.ExtractedValue;
            combinedObject[`Page Number${pageIdx + 0}`] = docDetails?.PageNumber;
            pageIdx++;
            const deciValue = docDetails?.MatchScore;
            var s = deciValue?.toFixed(2) || 0.00;
            combinedObject["Match Score %"] = s;
          }

          resultDataSet.push(combinedObject);
        }
      };
      const ids = id?.toString();
      const transformedJson = [
        {
          // Data: JSON.stringify(["DownloadTable"]),
          // TableData : [
          //   {
          TableName: "DownloadTable",
          Id: jobId,
          jobId: ids,
          Data: JSON.stringify(resultDataSet),
          //   }
          // ] 
        }
      ];
      await exportExcelData(transformedJson);
    } catch (error) {
      console.error("Error downloading job data:", error);
    }
  };

  const exportExcelData = async (Tabledata) => {
    document.body.classList.add('loading-indicator');
    const Token = await processAndUpdateToken(token);
    const headers = {
      'Authorization': `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/Excel/ExportExcel`;

    try {
      const response = await axios({
        method: "POST",
        url: apiUrl,
        headers: headers,
        data: {
          Data: JSON.stringify(["DownloadTable"]),
          Tabledata: Tabledata
        },
        responseType: 'blob'
      });
      if (response.status !== 200) {
        return "error";
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Tabledata[0].Id}GridExcel.xlsx`);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        container.current.showSnackbar('Downloaded file successfully', "success", true);
      }, 500);
      return "success";
    } catch (error) {
      container.current.showSnackbar('Error in downloading the file', "error", true);
      return "error";
    } finally {
      document.body.classList.remove('loading-indicator');
      // return "success";
    }
  };

  const handleFileChange = async (event, brokerId, id) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (selectedFile) {
      if (selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        alert("Only .xlsx files are allowed. Please select a valid file.");
        event.target.value = "";
        return;
      }

      setFile(selectedFile);
      await uploadCall(selectedFile, brokerId, id);
      event.target.value = "";
    }
  };

  const uploadCall = async (file, brokerId, id) => {
    document.body.classList.add('loading-indicator');
    const Token = await processAndUpdateToken(token);
    const headers = {
      'Authorization': `Bearer ${Token}`,
      "Content-Type": "multipart/form-data",
    };
    const apiUrl = `${baseUrl}/api/Excel/UploadExcelDynamic`;

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('brokerId', brokerId);
      formData.append('jobId', id);

      const response = await axios({
        method: "POST",
        url: `${apiUrl}?brokerId=${brokerId}&jobId=${id}`,
        headers: headers,
        data: formData
      });
      if (response.status !== 200) {
        return "error";
      }
      setTimeout(() => {
        container.current.showSnackbar('Uploaded file successfully', "success", true);
      }, 500);
      return "success";
    } catch (error) {
      container.current.showSnackbar('Error in uploading the file', "error", true);
      return "error";
    } finally {
      document.body.classList.remove('loading-indicator');
      // return "success";
    }
  };

  const redirectIntoJob = (jobId, id, brokerId) => {
    if (jobId && id > 0) {
      window.location.href = `/qcJob?jobId=${jobId}&id=${id}&brokerId=${brokerId}`
    }
  };

  // Fetch broker list on component mount
  useEffect(() => {
    const fetchBrokerList = async () => {
      try {
        document.body.classList.add("loading-indicator");
        const brokerList = await GetBrokerList();
        if (brokerList && brokerList.length > 0) {
          const dropDownData = brokerList.map((e) => ({
            key: e?.BrokerId,
            text: e?.VchBrokerName,
          }));
          setOptions(dropDownData);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Error fetching broker list:", error);
        setOptions([]);
        document.body.classList.remove("loading-indicator");
      }
      document.body.classList.remove("loading-indicator");
    };

    fetchBrokerList();
  }, []);

  useEffect(() => {
    const fetchJobInfo = async () => {
      if (brokerId && brokerId != 0) {
        document.body.classList.add("loading-indicator");
        try {
          const response = await GetJobInfo(brokerId);
          setTableData(response);
        } catch (error) {
          console.error("Error fetching job info:", error);
          document.body.classList.remove("loading-indicator");
          setTableData([]);
        }
        document.body.classList.remove("loading-indicator");
      } else {
        setTableData([]);
      }
    };

    fetchJobInfo();
  }, [brokerId]);

  const handleDropdownChange = (event, option) => {
    setBrokerId(option.key);
  };

  const filterData = () => {
    if (searchText && searchText?.length > 0) {
      const filteredData = tableData?.filter((f) =>
        f?.JobCode?.toLowerCase()
          ?.trim()
          ?.includes(searchText?.trim()?.toLowerCase())
      );
      setTableDataFiltered(filteredData);
      setIsSearch(true);
    } else {
      setTableDataFiltered([]);
      setIsSearch(false);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="app-container" style={{ overflowY: 'auto', scrollbarWidth: 'thin', height: '100vh' }}>
        <div style={{ padding: "10px", width: "95%" }}>
          <p style={{ fontSize: "15px", color: "rgb(135, 206, 235)" }}>
            Quote Compare
          </p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DropdownComponent
              key={"drop_1"}
              options={options}
              selectedKey={brokerId}
              onChange={handleDropdownChange}
              placeholder="Select an broker code"
            />
            <div style={{ display: "flex", justifyContent: "end" }}>
              <TextField
                placeholder="Search Job..."
                value={searchText}
                onChange={(e, newValue) => {
                  if (!newValue) {
                    setIsSearch(false);
                  }
                  setSearchText(newValue);
                }}
                style={{
                  width: "250px",
                  marginRight: "8px",
                }}
              />
              <PrimaryButton
                text={
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    Search
                  </span>
                }
                onClick={() => {
                  filterData();
                }}
                style={{
                  marginLeft: "-50px",
                  height: "32.1px",
                  fontSize: "10px",
                }}
              />
            </div>
          </div>
          <br />
          <div>
            <CommonDataTable
              key={"jData"}
              column={column}
              data={isSearch ? tableDataFiltered : tableData}
              height={'100%'}
            />
          </div>
          {/* Add rendering logic for tableData here */}
        </div>
      </div>
      <SimpleSnackbar ref={container} />
    </div>
  );
};

export default QuoteCompare;
