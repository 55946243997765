import React, { useEffect, useState } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Grid } from "@mui/material";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import { SimpleSnackbar } from "./SnackBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GridBackUpDataTableView from "./GridBackUpDataTableView";
import CommonDataTable from "./CommonDataTable";

const GridBackUpSection = () => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [setId, setSetId] = useState(0);
  const [JobId, setJobId] = useState(0);


  // const descriptionElementRef = React.useRef();
  // useEffect(() => {
  //   if (open) {
  //     const { current: descriptionElement } = descriptionElementRef;
  //     if (descriptionElement !== null) {
  //       descriptionElement.focus();
  //     }
  //   }
  // }, [open]);

  const getData = async () => {
    if (searchText && searchText?.length > 0) {
      let token = sessionStorage.getItem("token");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        let response = await axios.get(
          baseUrl +
            "/api/ProcedureData/GetGridBackUpDataList?JobId=" +
            searchText,
          {
            headers,
          }
        );
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
        if (response?.data) {
          response.data = response.data.map((e) => {
            if (e["CreatedOn"]) {
              e["CreatedOn"] = formatTo12HourIST(e["CreatedOn"]);
            }
            return e;
          });
          setJobInstance(response.data);
        } else {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };

  const column = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
    },
    {
      name: "Job Id",
      selector: (row) => row?.JobId,
    },
    {
      name: "CreatedOn",
      // selector: (row) => {
      //   if (row.CreatedOn != null && row.CreatedOn != undefined) {
      //     const createdDate = new Date(row.CreatedOn).toISOString();
      //     return createdDate;
      //   } else {
      //     return "";
      //   }
      // },
      cell: (row) => row?.CreatedOn,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <PrimaryButton
          className="ref_button"
          text={
            <span
              style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}
            >
              View
            </span>
          }
          styles={{
            rootHovered: {
              backgroundColor: "#251ca8",
            },
          }}
          onClick={() => {
            setSetId(row?.Id);
            setJobId(row?.JobId);
            handleDialogOpen();
          }}
        />
      ),
    },
  ];

  const formatTo12HourIST = (date) => {
    // Adjust the date to IST (UTC+5:30)
    date = new Date(date);
    // const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const istDate = new Date(date.getTime());

    // Get the date components
    const year = istDate.getFullYear();
    const month = (istDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const day = istDate.getDate().toString().padStart(2, "0");

    // Get the hours, minutes, and seconds
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();

    // Determine AM/PM suffix
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Format the date & time
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${period}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const handleSearch = () => {
    getData();
  };

  const handleDialogClose = (event, reason) => {
    if (reason == "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const handleDialogOpen = () => {
    
    setOpen(true);
  };

  return (
    <div style={{ width: "100%", padding: "0px", marginLeft: "5px" }}>
      <div>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <TextField
            placeholder="Search JobId..."
            value={searchText}
            onChange={(e, newValue) => {
              setSearchText(newValue);
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={handleSearch}
            style={{
              marginLeft: "-50px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
      </div>
      <br />
      <div>
        <CommonDataTable column={column} data={jobInstance} />
      </div>
      <div>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          fullScreen={true}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Grid BackUp Data - Id:{setId},Job_Id :{JobId}</span>
              <span style={{ fontSize: "smaller", color: "indianred" }}>
                Click 'esc' key to close
              </span>
            </div>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <GridBackUpDataTableView data={{ setId, JobId: searchText }} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default GridBackUpSection;
