import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

const CommonDataTable = (props) => {
  const rowsPerPageOptions = [10, 20, 30, 40, 50, 100];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [data, setDatas] = useState([]);
  const [column, setColumns] = useState([]);

  useEffect(() => {
    setDatas(props?.data || []);
    setColumns(props?.column || []);
  }, [props]);

  const handlePageChanges = (page) => {
    if (page >= 1 && page <= Math.ceil(data / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage, currentPage) => {
    setCurrentPage(currentPage);
    setItemsPerPage(newRowsPerPage);
  };

  return (
    <div>
      {data.length > 0 ? (
        <DataTable
          columns={column}
          data={data}
          fixedHeader
          pagination
          paginationPerPage={itemsPerPage}
          paginationRowsPerPageOptions={rowsPerPageOptions}
          paginationRowsPerPageText="Rows per page:"
          paginationServer={false}
          paginationTotalRows={data.length}
          paginationComponentOptions={{
            page: currentPage,
            rowsPerPage: itemsPerPage,
            serverSide: true,
            showFirstLastPageButtons: false,
          }}
          customStyles={{
            headRow: {
              style: {
                background: "gainsboro", // Set your desired background color here
              },
            },
            pagination: {
              style: {
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                maxHeight: "400px",
                background: "whitesmoke",
                padding: "10px",
              },
            },
            table: {
              style: {
                height: props?.height || "50vh", // Set the height to 80vh
              },
            },
            rows: {
              style: {
                padding: "10px !important",
                height: "max-content",
                minHeight: "auto !important",
              },
            },
          }}
          onChangePage={(page) => handlePageChanges(page)}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) =>
            handleRowsPerPageChange(currentRowsPerPage, currentPage)
          }
        />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              padding: "15px",
              background: "gainsboro",
              fontSize: "11px",
              justifyContent: "space-between",
            }}
          >
            {column.map((col, index) => (
              <div key={index}>{col.name}</div>
            ))}
          </div>
          <p style={{ textAlign: "center" }}>There are no records to display</p>
        </div>
      )}
    </div>
  );
};

export default CommonDataTable;
