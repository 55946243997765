import React, { useState, useEffect } from "react";
import Switch from "@mui/material/Switch";
import { baseUrl } from "../Services/Constants";
import axios from "axios";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import { JobConfigData } from "../Services/PreviewChecklistDataService";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  UpdateFormsComapreConfig,
  UpdateLobSplitConfig,
  GetAvailableLob,
  UpdateColumnConfig,
  AddTableInfoInAppConfig,
  DeleteFormsTableConfig,
} from "../Services/JobConfigurationServices";

const dropdownStyles = { dropdown: { width: 500 }, root: { height: 100 } };

const DropdownErrorExampleOptions = [
  {
    key: "Do the Forms, Endorsements and Edition Dates match the source documents?",
    text: "Do the Forms, Endorsements and Edition Dates match the source documents?",
  },
  {
    key: "Do the Forms, Endorsements and Edition Dates match the expiring policy?",
    text: "Do the Forms, Endorsements and Edition Dates match the expiring policy?",
  },
  {
    key: "Are the forms and endorsements listed, attached in current term policy?",
    text: "Are the forms and endorsements listed, attached in current term policy?",
  },
  {
    key: "Are the forms and endorsements attached, listed in current term policy?",
    text: "Are the forms and endorsements attached, listed in current term policy?",
  },
];

const JobConfigurationDialog = (props) => {
  const [appConfigData, SetAppConfigData] = useState([]);
  const [fileData, SetFileData] = useState([]);
  const [selectedColumns, SetSelectedColumns] = useState({
    JobCommonDeclaration: ["Application"],
  });
  const [isFormsCompareApplicable, SetIsFormsCompareApplicable] =
    useState(false);
  const [isLobSplitApplicable, SetIsLobSplitApplicable] = useState(false);
  const [open, setOpen] = useState(false);
  const [process, SetProcess] = useState({
    processName: "",
    selectedTable: "",
  });
  const [policySelectedValue, SetPolicySelectedValue] = useState("");
  const [selectedTableValue, SetSelectedTableValue] = useState("");
  const [availableLobs, SetAvailableLobs] = useState({});
  const [canDisplay, SetCanDisplay] = useState(false);
  const [ddOpen, SetDDOpen] = useState(false);
  const [tableToRemove, SetTableToRemove] = useState("");

  const columnMaster = {
    JobCommonDeclaration: [
      "Current Term Policy",
      "Prior Term Policy",
      "Binder",
      "Proposal",
      "Quote",
      "Application",
      "Schedule",
    ],
    JobCoverages: [
      "Current Term Policy",
      "Prior Term Policy",
      "Binder",
      "Proposal",
      "Quote",
      "Application",
      "Schedule",
    ],
    Tbl_ChecklistForm1: [
      "Current Term Policy - Listed",
      "Prior Term Policy - Listed",
      "Proposal - Listed",
      "Binder - Listed",
      "Schedule - Listed",
      "Quote - Listed",
      "Application - Listed",
    ],
    Tbl_ChecklistForm2: [
      "Current Term Policy - Listed",
      "Prior Term Policy - Listed",
      "Current Term Policy Listed",
      "Current Term Policy Attached",
    ],
    Tbl_ChecklistForm3: [
      "Current Term Policy Listed",
      "Current Term Policy Attached",
    ],
    Tbl_ChecklistForm4: [
      "Current Term Policy Attached",
      "Current Term Policy Listed",
    ],
  };

  useEffect(() => {
    if (props?.data?.JobId && props?.data?.JobId?.length > 0) {
      getData(props?.data?.JobId);
    }
  }, [props?.data?.JobId]);

  const getData = async (JobId) => {
    const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
    await getDocumentData(JobId, Token);
    await loadJobData(JobId, Token);
    SetCanDisplay(true);
  };

  const loadJobData = async (JobId, Token) => {
    document.body.classList.add("loading-indicator");
    try {
      await getConfigData(JobId, Token);
      const response = await GetAvailableLob(JobId);
      SetAvailableLobs(response ? response : {});
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      document.body.classList.remove("loading-indicator");
    }
  };

  const getConfigData = async (JobId, Token) => {
    const configResponse = await JobConfigData(JobId, Token);
    if (
      configResponse &&
      Array.isArray(configResponse) &&
      configResponse?.length > 0
    ) {
      SetIsFormsCompareApplicable(
        configResponse?.some((f) => f?.isFormCompareApplicable === true)
      );
      SetIsLobSplitApplicable(
        configResponse?.some((f) => f?.isMultipleLobSplit === true)
      );
      SetAppConfigData(configResponse);
    }
  };

  const getDocumentData = async (JobId, Token) => {
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${baseUrl}/api/Defaultdatum/GetDocumentDetails?jobId=${JobId}`,
      {
        headers,
      }
    );
    if (
      response?.data &&
      Array.isArray(response?.data) &&
      response?.data?.length > 0
    ) {
      SetFileData(response?.data);
    }
  };

  const fcToggleChange = (event) => {
    SetIsFormsCompareApplicable(event.target.checked);
  };

  const lobToggleChange = (event) => {
    SetIsLobSplitApplicable(event.target.checked);
  };

  const handleDialogClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
    SetDDOpen(false);
    SetProcess({
      processName: "",
      selectedTable: "",
    });
    SetTableToRemove("");
    SetPolicySelectedValue("");
    SetSelectedTableValue("");
  };

  const handleTableSubmission = async () => {
    if (
      selectedTableValue &&
      selectedTableValue?.length > 0 &&
      policySelectedValue &&
      policySelectedValue?.length > 0
    ) {
      const data = {
        JobId: props?.data?.JobId,
        TableName: selectedTableValue,
        ColumnData: JSON.stringify([]),
        PolicyLob: policySelectedValue?.trim(),
      };
      try {
        document.body.classList.add("loading-indicator");
        const response = await AddTableInfoInAppConfig(data);
        if (!response != "failed") {
          handleDialogClose("", "successCall");
          await loadJobData(
            props?.data?.JobId,
            sessionStorage.getItem("token")
          );
        }
        document.body.classList.remove("loading-indicator");
      } catch (error) {
        document.body.classList.remove("loading-indicator");
      }
    }
  };

  const handleTableRemovalSubmission = async () => {
    document.body.classList.add("loading-indicator");
    try {
      await DeleteFormsTableConfig(props?.data?.JobId, tableToRemove);
      handleDialogClose("", "successCall");
      await loadJobData(props?.data?.JobId, sessionStorage.getItem("token"));
    } catch (error) {
      document.body.classList.remove("loading-indicator");
    }
    document.body.classList.remove("loading-indicator");
  };

  const renderAddSection = () => {
    const tableList = [
      "JobHeader",
      "JobCommonDeclaration",
      "JobCoverages",
      "Tbl_ChecklistForm1",
      "Tbl_ChecklistForm2",
      "Tbl_ChecklistForm3",
      "Tbl_ChecklistForm4",
    ];
    const availableTables = appConfigData.map((e) => e?.Tablename);
    const filteredTableList = tableList.filter(
      (f) => !availableTables?.includes(f)
    );
    if (filteredTableList?.length === 0) {
      return (
        <div style={{ width: "100%" }}>
          <p>No Tables Are Available to Add. All 7 Tables already exist.</p>
        </div>
      );
    } else {
      const tableOptions = filteredTableList.map((e) => ({
        key: e,
        text: e,
      }));
      const avLobs = Object?.values(availableLobs);
      let availableOptions = [];
      if (avLobs && avLobs?.length > 0) {
        availableOptions = DropdownErrorExampleOptions.filter(
          (f) => !avLobs?.includes(f?.key)
        );
      } else {
        availableOptions = DropdownErrorExampleOptions;
      }
      return (
        <div style={{ width: "100%" }}>
          <div>
            <Dropdown
              placeholder="Select a Table Name"
              label="Table"
              selectedKey={selectedTableValue}
              options={tableOptions}
              errorMessage={
                !selectedTableValue
                  ? "Table name is mandatory to create a table section"
                  : undefined
              }
              onChange={(e, item) => {
                SetSelectedTableValue(item?.key || "");
              }}
              styles={dropdownStyles}
            />
          </div>
          <div>
            <Dropdown
              placeholder="Select a Policy Lob"
              label="Policy Lob"
              selectedKey={policySelectedValue}
              options={availableOptions}
              errorMessage={
                !policySelectedValue
                  ? "Policy Lob is mandatory to create a table section"
                  : undefined
              }
              onChange={(e, item) => {
                SetPolicySelectedValue(item?.key || "");
              }}
              styles={dropdownStyles}
            />
          </div>
        </div>
      );
    }
  };

  const getPLdata = (tablename) => {
    return availableLobs[tablename] || "";
  };

  const getACNText = (tableName, lob) => {
    let text = "";
    if (tableName === "Tbl_ChecklistForm2") {
      text += "  The Columns are -> ";
      if (
        [
          "Do the Forms, Endorsements and Edition Dates match the source documents?",
          "Do the Forms, Endorsements and Edition Dates match the expiring policy?",
        ]?.includes(lob)
      ) {
        text += "Current Term Policy - Listed & Prior Term Policy - Listed";
      } else if (
        [
          "Are the forms and endorsements listed, attached in current term policy?",
        ]?.includes(lob)
      ) {
        text += "Current Term Policy Listed & Prior Term Policy Listed";
      }
    }
    return text;
  };

  const columnSelectionChange = (checked, value, tablename) => {
    let data = [...appConfigData];
    // console.log(data);
    data = data.map((item) => {
      if (item?.Tablename === tablename) {
        if (checked) {
          item["TemplateData"] = [...item["TemplateData"], ...[value]];
        } else {
          item["TemplateData"] = item["TemplateData"]?.filter(
            (f) => f != value
          );
        }
      }
      return item;
    });
    // console.log(data);
    SetAppConfigData(data);
  };

  const onUpdateTableClick = async (tableName) => {
    if (tableName) {
      try {
        document.body.classList.add("loading-indicator");
        const tableRecord = appConfigData?.find(
          (f) => f?.Tablename === tableName
        )?.TemplateData;
        if (tableRecord && tableRecord?.length > 0) {
          let columnData = [];
          if (
            [
              "JobCommonDeclaration",
              "JobCoverages",
              "Tbl_ChecklistForm1",
              "Tbl_ChecklistForm2",
            ]?.includes(tableName)
          ) {
            if (
              tableName === "Tbl_ChecklistForm2" &&
              (availableLobs[tableName] ===
                "Are the forms and endorsements listed, attached in current term policy?" ||
                availableLobs[tableName] ===
                  "Are the forms and endorsements attached, listed in current term policy?")
            ) {
              columnData = [
                "Coverage_Specifications_Master",
                "Checklist Questions",
                "Observation",
                "Policy LOB",
                "Page Number",
              ];
            } else {
              columnData = [
                "COVERAGE_SPECIFICATIONS_MASTER",
                "Checklist Questions",
                "OBSERVATION",
                "POLICY LOB",
                "Page Number",
              ];
            }
          } else {
            columnData = [
              "Coverage_Specifications_Master",
              "Checklist Questions",
              "Observation",
              "Policy LOB",
              "Page Number",
            ];
          }
          tableRecord?.forEach((f) => {
            const hasRecord = columnData?.filter(
              (cd) => cd?.toLowerCase()?.trim() === f?.toLowerCase()?.trim()
            );
            if (hasRecord?.length === 0) {
              columnData.push(f);
            }
          });
          const data = {
            JobId: props?.data?.JobId,
            TableName: tableName,
            ColumnData: JSON.stringify(columnData || []),
            PolicyLob: availableLobs[tableName],
          };
          await UpdateColumnConfig(data);
          const token = sessionStorage.getItem("token");
          await loadJobData(props?.data?.JobId, token);
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
      }
      document.body.classList.remove("loading-indicator");
    }
  };

  const updateFCConfig = async () => {
    await UpdateFormsComapreConfig(
      props?.data?.JobId,
      isFormsCompareApplicable
    );
    await loadJobData(props?.data?.JobId, sessionStorage.getItem("token"));
  };

  const updateSLConfig = async () => {
    await UpdateLobSplitConfig(props?.data?.JobId, isLobSplitApplicable);
    await loadJobData(props?.data?.JobId, sessionStorage.getItem("token"));
  };

  return (
    <div>
      <div>
        <span>
          <b>Form Compare Applicable</b>
        </span>
        <Switch
          checked={isFormsCompareApplicable}
          onChange={fcToggleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            updateFCConfig();
          }}
        >
          Update
        </Button>
        <br />
        <span>
          <b>Lob Split Applicable</b>
        </span>
        <Switch
          checked={isLobSplitApplicable}
          onChange={lobToggleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            updateSLConfig();
          }}
        >
          Update
        </Button>
      </div>
      <div>
        <b>Available Files</b>
        <ol>
          {fileData?.map((e, index) => (
            <li key={index}>
              <b>{e.FileFor}</b>&nbsp;&nbsp;-&nbsp;&nbsp;
              <span>{e.FileName}</span>
            </li>
          ))}
        </ol>
      </div>
      <br />
      <div>
        <b>Tables</b>
      </div>
      <div>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
            SetProcess({
              processName: "AddTable",
              selectedTable: "",
            });
          }}
        >
          Add Table
        </Button>
      </div>
      <br />
      <div>
        {appConfigData?.length > 0 &&
          appConfigData?.map((e, index) => {
            if (e?.Tablename !== "JobHeader") {
              let selectedTableMaster = columnMaster[e?.Tablename];
              // let selectedTableMaster = [];
              // if (
              //   e?.Tablename === "Tbl_ChecklistForm2" &&
              //   appConfigData.some((f) => f?.Tablename === "Tbl_ChecklistForm4")
              // ) {
              //   const dataToFilter = columnMaster[e?.Tablename];
              //   selectedTableMaster = dataToFilter.filter(
              //     (f) =>
              //       ![
              //         "Current Term Policy Listed",
              //         "Current Term Policy Attached",
              //       ]?.includes(f)
              //   );
              // } else {
              //   selectedTableMaster = columnMaster[e?.Tablename];
              // }
              let formLobList = [];
              if (e?.Tablename === "Tbl_ChecklistForm1") {
                formLobList = DropdownErrorExampleOptions?.filter(
                  (f) =>
                    ![
                      "Are the forms and endorsements listed, attached in current term policy?",
                      "Are the forms and endorsements attached, listed in current term policy?",
                    ]?.includes(f?.key)
                );
              } else if (e?.Tablename === "Tbl_ChecklistForm2") {
                formLobList = DropdownErrorExampleOptions?.filter(
                  (f) =>
                    ![
                      "Do the Forms, Endorsements and Edition Dates match the source documents?",
                      "Are the forms and endorsements attached, listed in current term policy?",
                    ]?.includes(f?.key)
                );
              } else if (e?.Tablename === "Tbl_ChecklistForm3") {
                formLobList = DropdownErrorExampleOptions?.filter((f) =>
                  [
                    "Are the forms and endorsements listed, attached in current term policy?",
                    "Are the forms and endorsements attached, listed in current term policy?",
                  ]?.includes(f?.key)
                );
              } else if (e?.Tablename === "Tbl_ChecklistForm4") {
                formLobList = DropdownErrorExampleOptions?.filter((f) =>
                  [
                    "Are the forms and endorsements listed, attached in current term policy?",
                    "Are the forms and endorsements attached, listed in current term policy?",
                  ]?.includes(f?.key)
                );
              }
              return (
                <Accordion key={"a_" + index}>
                  <AccordionSummary
                    expandIcon={<Icon iconName="ChevronDown" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <b>{e?.Tablename}</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    {[
                      "Tbl_ChecklistForm1",
                      "Tbl_ChecklistForm2",
                      "Tbl_ChecklistForm3",
                      "Tbl_ChecklistForm4",
                    ]?.includes(e?.Tablename) && (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "50px",
                          }}
                        >
                          <Button
                            color="error"
                            onClick={() => {
                              SetDDOpen(true);
                              SetTableToRemove(e?.Tablename);
                            }}
                          >
                            Delete Table
                          </Button>
                        </div>
                        <div>
                          <Dropdown
                            placeholder="Select a Policy Lob"
                            label="Policy Lob"
                            selectedKey={availableLobs[e?.Tablename]}
                            options={formLobList}
                            errorMessage={
                              !availableLobs[e?.Tablename]
                                ? "Policy Lob is mandatory to create a table section"
                                : undefined
                            }
                            onChange={(event, item) => {
                              const tLData = { ...availableLobs };
                              tLData[e?.Tablename] = item?.key || "";
                              SetAvailableLobs(tLData);
                            }}
                            styles={dropdownStyles}
                          />
                        </div>
                        <div>
                          {e?.Tablename === "Tbl_ChecklistForm2" && (
                            <div>
                              <span>
                                For Lob - "{availableLobs[e?.Tablename]}".
                                &nbsp;&nbsp;
                              </span>
                              <span>
                                {getACNText(
                                  e?.Tablename,
                                  availableLobs[e?.Tablename]
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div>
                      {selectedTableMaster?.map((e1, sIndex) => (
                        <div key={`c_${index}_${sIndex}`}>
                          <Checkbox
                            checked={e?.TemplateData?.includes(e1)}
                            // disabled={[
                            //   "Tbl_ChecklistForm1",
                            //   "Tbl_ChecklistForm2",
                            //   "Tbl_ChecklistForm3",
                            //   "Tbl_ChecklistForm4",
                            // ]?.includes(e?.Tablename)}
                            onChange={(event) => {
                              columnSelectionChange(
                                event?.target?.checked,
                                e1,
                                e?.Tablename
                              );
                            }}
                          />
                          &nbsp;&nbsp;&nbsp;
                          <span>{e1}</span>
                        </div>
                      ))}
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          onUpdateTableClick(e?.Tablename);
                        }}
                      >
                        Update Table
                      </Button>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            } else if (e?.Tablename === "JobHeader") {
              return <p key={index}>Table JobHeader exists.</p>;
            }
          })}
      </div>
      <div style={{ width: "100%" }}>
        <Dialog
          open={open}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          style={{ width: "100%" }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {process?.processName === "AddTable" && (
                <span>Job - Configurations</span>
              )}
              {process?.processName === "" && <span>Job - Configurations</span>}
            </div>
          </DialogTitle>
          <DialogContent dividers={true} component={"div"}>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              component={"div"}
            >
              {process?.processName === "AddTable" && renderAddSection()}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleTableSubmission();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ width: "100%" }}>
        <Dialog
          open={ddOpen}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          style={{ width: "100%" }}
        >
          <DialogTitle id="scroll-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {process?.processName === "AddTable" && (
                <span>Job - Configurations</span>
              )}
              {process?.processName === "" && <span>Job - Configurations</span>}
            </div>
          </DialogTitle>
          <DialogContent dividers={true} component={"div"}>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              component={"div"}
            >
              <p>
                Are you sure you want to remove the table {tableToRemove}. Note
                that by doing this action the data will also be deleted and it
                is also an non-revertable if we don't have any saved back-up
                data.
              </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button
              onClick={() => {
                handleTableRemovalSubmission();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {!canDisplay && <p>Loading data....</p>}
    </div>
  );
};

export default JobConfigurationDialog;
