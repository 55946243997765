import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GetJobFiles, GetJobQCData, GetLobQuestionsByJobId, quoteCompareTblsData } from "../../Services/QuoteCompareService";
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Disclaimer, baseUrl } from "../../Services/Constants";
import { Icon } from '@fluentui/react';
import axios from "axios";
import { InputDialogComponent, FilterQcDialogComponent, FindDialogComponent } from '../../Services/dialogComponent';
import { getText, processAndUpdateToken, getEmptyDataSet, findTableForIndex, groupNumbers } from "../../Services/CommonFunctions"
import { SimpleSnackbar } from "../SnackBar";

const luckyCss = {
  margin: "0px",
  padding: "0px",
  position: "absolute",
  width: "100% !important",
  height: "40%",
  left: "0px",
  top: "0px",
};

const apiDataConfig = {
  demo: {
    name: "Quote Compare", // Worksheet name
    color: "", // Worksheet color
    config: {
      merge: {
        "0_1": {
          rs: 1,
          cs: 6,
          r: 0,
          c: 1,
        },
        "1_1": {
          rs: 1,
          cs: 2,
          r: 0,
          c: 1,
        },
      },
      borderInfo: [],
      rowlen: {
        0: 20,
        1: 20,
        2: 35,
        3: 35,
        4: 50,
        5: 35,
        6: 35,
        7: 35,
        8: 35,
        9: 35,
        10: 35,
        11: 35,
        12: 35,
        13: 35,
      },
      columnlen: {
        1: 300,
        2: 300,
        3: 300,
        4: 300,
        5: 300,
        6: 300,
        7: 300,
        8: 300,
        9: 300,
        10: 300,
        11: 300,
        12: 300,
        13: 300,
        14: 300,
        15: 300,
        16: 300,
        17: 300,
        18: 300,
        19: 300,
        20: 300
      },
      curentsheetView: "viewPage",
      sheetViewZoom: {
        viewNormalZoomScale: 0.6,
        viewPageZoomScale: 0.6,
      },
    },
    chart: [],
    status: "1",
    order: "0",
    hide: 0,
    column: 50,
    row: 50,
    celldata: [],
    ch_width: 2322,
    rh_height: 949,
    scrollLeft: 0,
    scrollTop: 0,
    luckysheet_select_save: [],
    calcChain: [],
    isPivotTable: false,
    pivotTable: {},
    filter_select: null,
    filter: null,
    luckysheet_alternateformat_save: [],
    luckysheet_alternateformat_save_modelCustom: [],
    sheets: [],
  },
};

const QCDataRenderer = (props) => {
  const container = useRef();
  const luckysheet = window.luckysheet;
  const location = useLocation();
  const [openInputDialog, setOpenInputDialog] = useState(false);
  const [selectedRowIndexRange, setSelectedRowIndexRange] = useState([]);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState('');
  const [msgText, setMsgText] = useState('');
  const [hasMultipleRowsSelected, setHasMultipleRowsSelected] = useState(false);
  const [setectedRowIndex, setSelectedRowIned] = useState('');
  const [fileState, setFileState] = useState([]);
  const [jobData, SetJobData] = useState([]);
  const [findDialog, setfindDialog] = useState(false);
  const [sheetState, setsheetState] = useState([]);
  const [jobDetails, SetJobDetails] = useState({ JobId: '0', Id: 0, BrokerId: 0 });
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [filterSelectionData, setFilterSelectionData] = useState(null);
  const [dataSet, setDataSet] = useState([]);

  let staticTb1Data = quoteCompareTblsData;
  const token = sessionStorage.getItem('token');
  const defaultFs = 8;
  const [quoteTableColumnDetails, setQuoteTableColumnDetails] = useState({ "Table 1": { "columnNames": {}, "range": { "start": "", "end": "" } }, "Table 2": { "columnNames": {}, "range": { "start": "", "end": "" } }, "Table 3": { "columnNames": {}, "range": { "start": "", "end": "" } }, "Table 4": { "columnNames": {}, "range": { "start": "", "end": "" } } });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const jobId = queryParams.get("jobId");
    const id = queryParams.get("id");
    const brokerId = queryParams.get("brokerId");
    SetJobDetails({
      JobId: jobId, Id: id, BrokerId: brokerId
    });

    const renderData = async () => {
      try {
        container.current.showSnackbar('Fetching data.....', "info", true);
        const { fileResponse, jobData, jobLobQuestionData } = await getJobFileRecords(id, jobId, brokerId);
        SetJobData(jobData);
        if (fileResponse && fileResponse?.length > 0) {
          await renderChecklistTbl1(fileResponse, jobData, jobLobQuestionData);
        } else {
          console.warn("No file data available to render");
        }
        setTimeout(() => {
          container.current.showSnackbar('Data rendered Successfully', "success", true);
        }, 500);
      } catch (error) {
        console.error("Error fetching job data:", error);
        container.current.showSnackbar('Error fetching data', "error", true);
      }
    };

    renderData();
  }, [location.search]);

  const processQuestionCodes = (questionCodes, filteredData, fileNameVle, varianceValue, questionsValue, sortFiles, jobDatas) => {   // Common function to structure all tables data for render 
    const result = [];
    const getVariances = (questionCode) => {       // function for showing and mapping CoverageSpecification column values
      const findData = varianceValue?.find(code => code?.startsWith(questionCode));
      return findData ? findData.split(' = ')[1] : "Details not available in the document";
    };

    const getChecklistQueVle = (questionCode) => {       // function for showing and mapping CheckListQuestion column values
      const findData = questionsValue?.find(code => code?.startsWith(questionCode));
      return findData ? findData.split(' = ')[1] : "Details not available in the document";
    };

    const quoteFiltes = sortFiles?.filter((f) => f?.Column === "qc");
    const priorColFile = sortFiles?.filter((f) => f?.Column === "ptp");

    questionCodes.forEach(questionCode => {
      const matchedData = filteredData?.filter(item => item?.QuestionCode === questionCode);
      if (matchedData.length > 0) {         /// if block for mapping the Document file columns for Same GroupId's
        // Group data by GroupID
        const groupIds = Array?.from(new Set(matchedData?.map((e) => e?.GroupID)));

        groupIds.map((gId) => {
          const dataByGroupId = matchedData.filter((f) => f?.GroupID === gId);
          const variances = {};
          variances["CoverageSpecification"] = getVariances(questionCode);
          variances["ChecklistQuestion"] = getChecklistQueVle(questionCode);

          let ptpPageNo = '';
          if (priorColFile && priorColFile?.length > 0) {
            priorColFile?.forEach((item, index) => {
              const ptColValue = dataByGroupId?.find((f) => f?.DocumentName === item?.FileName)?.ExtractedValue;
              variances["BaseDocument"] = (ptColValue == null || ptColValue == "") ? "Details not available in the document" : ptColValue;

              const ptpPageValue = dataByGroupId?.find((f) => f?.DocumentName === item?.FileName)?.PageNum;
              ptpPageNo += `BD${index + 1}: ${ptpPageValue != null ? Number(ptpPageValue) : 0}`;
            });
          } else {
            variances["BaseDocument"] = "Details not available in the document";
            ptpPageNo += `BD: 0`;
          }

          let pageNumbers = '';
          quoteFiltes.forEach((item, index) => {
            variances[`Quote ${index + 1}` + (questionCode == "PT1" ? " - Listed" : "")] = dataByGroupId?.find((f) => f?.DocumentName === item?.FileName)?.ExtractedValue || "Details not available in the document";

            const pageNum = dataByGroupId?.find((f) => f?.DocumentName === item?.FileName)?.PageNum;
            pageNumbers += `Q${index + 1}: ${pageNum != null ? Number(pageNum) : 0}`;            // pageNumber column structure mapping based on DocumentName Column

            // Add a comma after each except the last one
            if (index < dataByGroupId?.length - 1) {
              pageNumbers += "; ";
            }
          });
          variances["PageNumber"] = `${ptpPageNo}; ${pageNumbers}`;
          const allMatchScores = Array?.from(new Set(dataByGroupId.map((item) => item.MatchScore)));

          if (allMatchScores.every((score) => score === 0)) {
            variances["Status"] = "Mismatch";
          } else if (allMatchScores.every((score) => score === 100)) {
            variances["Status"] = "Matched";
          } else {
            variances["Status"] = "Mismatch";
          }
          result.push(variances);
        });
      } else {                /// If no match, push default structure for mapping the Document file columns for different GroupTds
        const quoteCol = quoteFiltes?.map(f => f?.FileName);
        const priorCol = priorColFile?.map(f => f.FileName);

        const defaultGroup = {
          "CoverageSpecification": getVariances(questionCode),
          "ChecklistQuestion": getChecklistQueVle(questionCode),
        };

        if (priorCol && priorCol.length > 0) {
          priorCol.forEach(() => {
            defaultGroup["BaseDocument"] = "Details not available in the document";
          });
        } else {
          defaultGroup["BaseDocument"] = "Details not available in the document";
        }

        let pageNumbers = '';

        if (questionCode == "PT1") {
          quoteCol?.forEach((_, index) => {
            const quoteIndex = index + 1;
            defaultGroup[`Quote ${quoteIndex} - Listed`] = "Details not available in the document";

            pageNumbers += `Q${quoteIndex}: `;                  // if DocumentName is missing mapping static value 
            if (index < quoteCol?.length - 1) {
              pageNumbers += "; ";
            }
          });
          defaultGroup["PageNumber"] = pageNumbers;
          defaultGroup["Status"] = "Details not available";
        } else {
          quoteCol?.forEach((_, index) => {
            const quoteIndex = index + 1;
            defaultGroup[`Quote ${quoteIndex}`] = "Details not available in the document";

            pageNumbers += `Q${quoteIndex}: `;                  // if DocumentName is missing mapping static value 
            if (index < quoteCol?.length - 1) {
              pageNumbers += "; ";
            }
          });
          defaultGroup["PageNumber"] = pageNumbers;
          defaultGroup["Status"] = "Details not available";
        }
        result.push(defaultGroup);
      }
    });
    return result;      // returning table data here
  };

  const renderChecklistTbl1 = async (Data, jobData, lobQuestionData) => {              /// function for Table2 - Table4 Data structuring
    let iptFileSte = Data;  // Document files api data
    let jobDatas = jobData;  // GroupID api data to extracted Quote columns
    let lobQuestionDatas = lobQuestionData;  //  lob Data for all columns with key "IsCommon" boolean flag

    const iptFileFilter = iptFileSte?.filter(e => e.FileCategory != "Output File");
    let sortFiles = iptFileFilter?.sort((a, b) => a.FileID - b.FileID);

    let quoteIndex = 1;
    sortFiles = sortFiles.map((e) => {
      if (e?.DocumentType?.toLowerCase()?.includes("quote")) {
        e["Column"] = `qc`;
        e["Key"] = `Quote ${quoteIndex}`;
        e["key"] = `Q${quoteIndex}`;
        quoteIndex = quoteIndex + 1;
      } else if (e?.DocumentType?.toLowerCase()?.includes("base")) {
        e["Column"] = 'ptp'
        e["ptpKey"] = "BaseDocument";
        e["ptpkey"] = `BD${quoteIndex}`;
      }
      return e;
    });

    // sortFiles -> setstate
    setFileState(sortFiles);
    let fileNameVle = sortFiles?.map(e => e.FileName);                // array for the Document file

    /*Mapping file names in the header section*/

    if (staticTb1Data?.filter((f) => f?.Headers?.trim()?.toLowerCase()?.includes("documents used for policy review"))?.length > 0) {
      staticTb1Data = staticTb1Data.map((e) => {
        if (e?.Headers?.trim()?.toLowerCase()?.includes("documents used for policy review")) {
          e[""] = fileNameVle?.join(' , ');
        }
        return e;
      })
    } else {
      staticTb1Data.push({
        "HeaderID": "91",
        "JOBID": " ",
        "Headers": "Documents used for policy review",
        "": fileNameVle?.join(' , ')
      });
    }

    ////------------------------------------ Table 2 - ( Common_Declaration ) ------------------------------------------////

    let commomDeclationQData = lobQuestionDatas?.filter(g => g?.IsCommon);
    const sortedList = commomDeclationQData?.sort((a, b) => a.SortOrder - b.SortOrder);
    let cdTblQuestionCode = sortedList?.map(e => { return e?.QuestionCode?.toUpperCase() });     // Question Code Array value
    let cdCoverageSpecificationVlue = sortedList?.map(e => e?.CoverageSpecification);           // CoverageSpecification column Array value
    let cdChecklistQuestionVlue = sortedList?.map(e => e?.Question);                            // CheckListQuestion column Array value
    let format1 = cdTblQuestionCode?.map((code, index) => `${code} = ${cdCoverageSpecificationVlue[index]}`);   // array format with [ QuestionCode - CoverageSpecification_value ]
    let formatQue1 = cdTblQuestionCode?.map((code, index) => `${code} = ${cdChecklistQuestionVlue[index]}`);    // array format with [ QuestionCode - CheckListQuestion_value ]
    let filterCdTblQuestionCodeData = jobDatas?.filter(cde => cdTblQuestionCode?.includes(cde?.QuestionCode.toUpperCase()));  // array to filter the "Common_Declaration" QuestionCode from the overall jobData bsed on "IsCommon" flag


    ////------------------------------------ Table 3 - ( Job_Coverages ) ------------------------------------------////

    let jobCoverageData = lobQuestionDatas?.filter(g => !g?.IsCommon);
    let filterJobCoverageData = jobCoverageData?.filter(r => r.QuestionCode != "PT2" && r.QuestionCode != "PT1")
    const sortedList1 = filterJobCoverageData?.sort((a, b) => a.SortOrder - b.SortOrder);
    let jcTblQuestionCode = sortedList1?.map(e => { return e?.QuestionCode?.toUpperCase() });     // Question Code Array value
    let jcCoverageSpecificationVlue = sortedList1?.map(e => e?.CoverageSpecification);            // CoverageSpecification column Array value
    let jcChecklistQuestionVlue = sortedList1?.map(e => e?.Question);                              // CheckListQuestion column Array value
    let format2 = jcTblQuestionCode?.map((code, index) => `${code} = ${jcCoverageSpecificationVlue[index]}`);     // array format with [ QuestionCode - CoverageSpecification_value ]
    let formatQue2 = jcTblQuestionCode?.map((code, index) => `${code} = ${jcChecklistQuestionVlue[index]}`);      // array format with [ QuestionCode - CheckListQuestion_value ]
    let filterJcTblQuestionCodeData = jobDatas?.filter(cde => jcTblQuestionCode?.includes(cde?.QuestionCode.toUpperCase()));  // array to filter the "Job_Coverages" QuestionCode from the overall jobData bsed on "IsCommon" flag

    ////------------------------------------ Table 4 - ( Form_Table ) ------------------------------------------////

    let formTblData = jobCoverageData?.filter(r => !(r.QuestionCode != "PT1"));
    const sortedList2 = formTblData?.sort((a, b) => a.SortOrder - b.SortOrder);
    let formTblDataQuestionCode = sortedList2?.map(e => { return e?.QuestionCode?.toUpperCase() });    // Question Code Array value
    let formCoverageSpecificationVlue = sortedList2?.map(e => e?.CoverageSpecification);               // CoverageSpecification column Array value
    let formChecklistQuestionVlue = sortedList2?.map(e => e?.Question);                               // CheckListQuestion column Array value
    let format3 = formTblDataQuestionCode?.map((code, index) => `${code} = ${formCoverageSpecificationVlue[index]}`);  // array format with [ QuestionCode - CoverageSpecification_value ]
    let formatQue3 = formTblDataQuestionCode?.map((code, index) => `${code} = ${formChecklistQuestionVlue[index]}`);   // array format with [ QuestionCode - CheckListQuestion_value ]
    let filterFormTblDatauestionCodeData = jobDatas?.filter(cde => formTblDataQuestionCode?.includes(cde?.QuestionCode.toUpperCase()));  // array to filter the "Job_Coverages" QuestionCode from the overall jobData bsed on QuestionCode "PT1"

    // Common Function Call for Rendering // 

    const cdResult = processQuestionCodes(cdTblQuestionCode, filterCdTblQuestionCodeData, fileNameVle, format1, formatQue1, sortFiles, jobDatas);
    const jcResult = processQuestionCodes(jcTblQuestionCode, filterJcTblQuestionCodeData, fileNameVle, format2, formatQue2, sortFiles, jobDatas);
    const formTblResult = processQuestionCodes(formTblDataQuestionCode, filterFormTblDatauestionCodeData, fileNameVle, format3, formatQue3, sortFiles, jobDatas);

    //  Data Structuring in the object of array manner and pushing inside the "apiData" array

    const apiData = [
      {
        "Tablename": "Table 1",
        "TemplateData": staticTb1Data
      },
      {
        "Tablename": "Table 2",
        "TemplateData": cdResult
      },
      {
        "Tablename": "Table 3",
        "TemplateData": jcResult
      },
      {
        "Tablename": "Table 4",
        "TemplateData": formTblResult
      }
    ];

    let varianceDatas;
    if (apiData && apiData?.length > 0) {
      varianceDatas = [...apiData]
    } else {
      varianceDatas = [...apiData]
    };


    if (varianceDatas && varianceDatas?.length > 0) {
      varianceDatas = varianceDatas?.map((e) => {
        if (e?.TemplateData && typeof e?.TemplateData != 'object' && typeof e?.TemplateData === 'string') {
          let templateData = JSON?.parse(e.TemplateData);
          e["TemplateData"] = templateData;
        }
        return e;
      });
    }

    sessionStorage.setItem("dataSet", JSON.stringify(varianceDatas));
    setDataSet(varianceDatas);

    const tblData1 = varianceDatas?.find((e) => e?.Tablename === "Table 1");

    if (tblData1) {
      let tbl1json = tblData1?.TemplateData;

      let sheetDataTable2 = [];
      let renderAry = [];
      const rowIndexOfTbl1 = 3;

      const renderTextBlock = () => {
        const textBlock = [
          {
            ct: {
              fa: "General",
              t: "g",
            },
            fc: "#ff0000",
            ff: '"Tahoma"',
            m: Disclaimer,
            v: Disclaimer,
          },
        ];

        return textBlock;
      };

      const renderList = () => {
        const listBlock = [
          {
            ct: {
              fa: "General",
              t: "g",
            },
            fc: "#000000",
            ff: '"Tahoma"',
            m: "Quote Compare",
            v: "Quote Compare",
          },
        ];

        return listBlock;
      };

      const textBlockData = renderTextBlock();
      const listData = renderList();

      textBlockData.forEach((item, index) => {
        const mergeConfig = apiDataConfig?.demo?.config.merge["0_1"];

        sheetDataTable2.push({
          r: index + mergeConfig.r,
          c: mergeConfig.c,
          v: {
            ct: item.ct,
            m: item.m,
            v: item.v,
            bl: 1,
            ff: item.ff,
            fs: 8,
            merge: mergeConfig,
            fc: item.fc,
          },
        });
      });

      listData.forEach((item, index) => {
        const mergeConfig = apiDataConfig?.demo?.config.merge["1_1"];

        sheetDataTable2.push({
          r: 1 + mergeConfig.r,
          c: mergeConfig.c,
          v: {
            ct: item.ct,
            m: item.m,
            v: item.v,
            ff: item.ff,
            bl: 1,
            fs: `${defaultFs}`,
            merge: mergeConfig,
            fc: item.fc,
          },
        });
      });

      tbl1json.map((item, index) => {
        if (item["Headers"] != null && item["Headers"] != undefined) {
          renderAry.push({
            r: rowIndexOfTbl1 + index,
            c: 1,
            v: {
              ct: { fa: "@", t: "inlineStr", s: [{ v: item["Headers"], ff: "Tahoma", fs: `${defaultFs}` }] },
              m: item["Headers"],
              v: item["Headers"],
              merge: null,
              bg: "rgb(139,173,212)",
              tb: '2',
            }
          });
        }

        const tidleValue = item[""] !== null && item[""] != undefined ? item[""].replace(/~~/g, "\n") : "";

        renderAry.push({
          r: rowIndexOfTbl1 + index,
          c: 2,
          v: {
            ct: { fa: "@", t: "inlineStr" },
            m: tidleValue,
            v: tidleValue,
            ff: "\"Tahoma\"",
            fs: `${defaultFs}`,
            merge: null,
            tb: '2',
          }
        });
      });

      const dummyData = [];

      const matchedUnMatchedFilter = [
        {
          "r": 3,
          "c": 4,
          "v": {
            "ct": {
              "fa": "General",
              "t": "inlineStr",
              "s": [
                {
                  "fs": "10",
                  "v": "□ "
                },
                {
                  "vt": "0",
                  "ht": "1",
                  "un": 0,
                  "bl": 1,
                  "fc": "#0000ff",
                  "ff": "\"Tahoma\"",
                  "m": "All Variances",
                  "v": "All Variances",
                }
              ]
            },
            "merge": null,
            "w": 55,
            "tb": "2",
            "fc": "#0000ff",
            "fs": `${defaultFs}`
          }
        },
        {
          "r": 4,
          "c": 4,
          "v": {
            "ct": {
              "fa": "General",
              "t": "inlineStr",
              "s": [
                {
                  "fs": "10",
                  "v": "□ "
                },
                {
                  "vt": "0",
                  "ht": "1",
                  "un": 0,
                  "bl": 1,
                  "fc": "#0000ff",
                  "ff": "\"Tahoma\"",
                  "m": "Matched",
                  "v": "Matched"
                }
              ]
            },
            "merge": null,
            "w": 55,
            "tb": "2",
            "fc": "#0000ff",
            "fs": `${defaultFs}`
          }
        },
        {
          "r": 5,
          "c": 4,
          "v": {
            "ct": {
              "fa": "General",
              "t": "inlineStr",
              "s": [
                {
                  "fs": "10",
                  "v": "□ "
                },
                {
                  "vt": "0",
                  "ht": "1",
                  "un": 0,
                  "bl": 1,
                  "fc": "#0000ff",
                  "ff": "\"Tahoma\"",
                  "m": "Variances",
                  "v": "Variances"
                }
              ]
            },
            "merge": null,
            "w": 55,
            "tb": "2",
            "fc": "#0000ff",
            "fs": `${defaultFs}`
          }
        },
        {
          "r": 6,
          "c": 4,
          "v": {
            "ct": {
              "fa": "General",
              "t": "inlineStr",
              "s": [
                {
                  "fs": "10",
                  "v": "□ "
                },
                {
                  "vt": "0",
                  "ht": "1",
                  "un": 0,
                  "bl": 1,
                  "fc": "#0000ff",
                  "ff": "\"Tahoma\"",
                  "m": "Details not available in the document",
                  "v": "Details not available in the document"
                }
              ]
            },
            "merge": null,
            "w": 55,
            "tb": "2",
            "fc": "#0000ff",
            "fs": `${defaultFs}`
          }
        },
      ];

      const allRows = [...sheetDataTable2, ...matchedUnMatchedFilter, ...renderAry];

      if (renderAry && renderAry?.length > 0) {
        const table1RangeDetails = quoteTableColumnDetails;
        table1RangeDetails["Table 1"] = { "columnNames": tbl1json.map((e) => e?.Headers), "range": { "start": 0, "end": renderAry[renderAry?.length - 1]?.r } };
        setQuoteTableColumnDetails(table1RangeDetails);
      }

      allRows.sort((a, b) => a.r - b.r);

      dummyData.push(...allRows);
      apiDataConfig.demo.config.borderInfo.push({
        "rangeType": "range",
        "borderType": "border-all",
        "color": "#000",
        "style": "1",
        "range": [
          {
            "left": 857,
            "width": 250,
            "top": 114,
            "height": 50,
            "left_move": 857,
            "width_move": 250,
            "top_move": 114,
            "height_move": 122,
            "row": [
              3,
              6
            ],
            "column": [
              4,
              4
            ],
            "row_focus": 4,
            "column_focus": 4
          }
        ]
      });
      apiDataConfig.demo.celldata = dummyData;

      allRows.forEach((row) => {
        if (renderAry.includes(row)) {
          apiDataConfig.demo.config.borderInfo.push({
            "rangeType": "cell",
            "value": {
              "row_index": row?.r,
              "col_index": row?.c,
              "l": {
                "style": 1,
                "color": "#000"
              },
              "r": {
                "style": 1,
                "color": "#000"
              },
              "t": {
                "style": 1,
                "color": "#000"
              },
              "b": {
                "style": 1,
                "color": "#000"
              }
            }
          });
        }
      });
      varianceDatas.map((f, index) => {
        if (f?.Tablename != 'Table 1' && f?.TemplateData?.length >= 1) {
          let filteredData = apiDataConfig.demo.celldata?.filter((a, index) => a != null || !a);
          renderVarianceTbls([...filteredData], f?.Tablename, varianceDatas, lobQuestionDatas);
        }
      });
    }
    renderLuckySheet();

    setTimeout(() => {
      dataGrouping();
    }, 2000);
  };

  const renderVarianceTbls = (continuesData, tableName, apiData, lobQuestionDatas) => {
    const basedata = [...continuesData];

    const tableColumnNames = {};
    const documentViewer = true;
    const quoteVarainceCol = ["Action from Variances by MMA", "Actions on Variances", "Request Endorsement", "Notes", "Notes (Free Fill)"];

    const lobValueTbl3 = lobQuestionDatas?.map(lob => lob?.LOB);   /// array for the Policy_Lob row for the "Table 3"
    sessionStorage.setItem('lobValueTbl3', JSON.stringify(lobValueTbl3));

    const tableData = apiData?.find((data) => data?.Tablename === tableName);
    if (!tableData) {
      console.log("No Data found")
      return;
    }

    let tableTemplateData = tableData?.TemplateData;
    let header = Object.keys(tableTemplateData[0]);
    let headerMapping = [];
    let lobRowStartIdx = 0;
    let lobRowEndIdx = 0;

    if (tableName === "Table 3") {
      lobRowStartIdx = basedata[basedata?.length - 1]?.r + 2;
      headerMapping = [
        {
          r: basedata[basedata?.length - 1]?.r + 2,
          rs: 1,
          c: 1,
          cs: header.length + 1,
          v: {
            ct: { fa: "General", t: "g" },
            m: lobValueTbl3[0],
            v: lobValueTbl3[0],
            fs: `${defaultFs}`,
            ff: "\"Tahoma\"",
            bg: "rgb(139,173,212)",
            tb: '2',
            w: 55,
          }
        }
      ]
    };

    const removalCode = header.map(item => {

      if (tableName === "Table 2" && (item === "COVERAGE_SPECIFICATIONS_MASTER" || item === "CoverageSpecification")) {
        return "Common Declarations";
      } else if (tableName === "Table 3" && (item === "COVERAGE_SPECIFICATIONS_MASTER" || item === "CoverageSpecification")) {
        return "COVERAGE SPECIFICATIONS";
      } else if (tableName === "Table 4" && (item === "COVERAGE_SPECIFICATIONS_MASTER" || item === "CoverageSpecification")) {
        return "Do the Forms, Endorsements and Edition Dates match the source documents?";
      }
      else {
        return item;
      }
    });

    headerMapping = [
      ...headerMapping,
      ...removalCode.map((item, index) => {

        if (index === 0) {
          apiDataConfig.demo.config.merge[`${tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 2 : basedata[basedata?.length - 1]?.r + 2}_${index}`] = {
            "r": tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 2 : basedata[basedata?.length - 1]?.r + 2,
            "c": index,
            "rs": tableName === "Table 3" ? 3 : 2,
            "cs": 1
          }
        }
        apiDataConfig.demo.config.merge[`${tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2}_${1 + index}`] = {
          "r": tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
          "c": 1 + index,
          "rs": 2,
          "cs": 1
        }

        return {
          r: tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
          rs: 2,
          c: 1 + index,
          cs: 1,
          v: {
            ct: { fa: "General", t: "g" },
            m: item,
            v: item,
            fs: `${defaultFs}`,
            fc: "#000000",
            ff: "\"Tahoma\"",
            merge: null,
            bg: "rgb(139,173,212)",
            tb: '2',
            w: 55,
          }
        }
      }),
    ];

    if (headerMapping?.length > 0) {
      headerMapping.forEach((f, index) => {
        if (tableName === "Table 3" && (index === 0 || index === 1)) {
          tableColumnNames["CoverageSpecification"] = f?.c;
        } else if (index === 0) {
          tableColumnNames["CoverageSpecification"] = f?.c;
        } else {
          tableColumnNames[f?.v?.v] = f?.c;
        }
      });
    }

    if (documentViewer) {
      apiDataConfig.demo.config.merge[`${tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2}_${1 + headerMapping[headerMapping?.length - 1]?.c}`] = {
        "r": tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
        "c": 1 + headerMapping[headerMapping?.length - 1]?.c,
        "rs": 2,
        "cs": 1
      }

      const DocumentViewer = [{
        r: tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
        rs: 2,
        c: 1 + headerMapping[headerMapping?.length - 1]?.c,
        cs: 1,
        v: {
          ct: { fa: "General", t: "g" },
          m: 'Document Viewer',
          v: 'Document Viewer',
          ff: "\"Tahoma\"",
          fs: `${defaultFs}`,
          merge: null,
          bg: "rgb(139,173,212)",
          tb: '2',
          w: 55,
        }
      }];
      headerMapping = [...headerMapping, ...DocumentViewer];
    }

    const varainceHeaderRows = quoteVarainceCol.map((item, index) => {
      if (quoteVarainceCol?.length === index + 1) {
        lobRowEndIdx = tableName === "Table 3" ? headerMapping.length + index : headerMapping.length + index;
      }
      if (index == 0) {
        apiDataConfig.demo.config.merge[`${tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2}_${tableName === "Table 3" ? headerMapping?.length + index : headerMapping?.length + index + 1}`] = {
          "r": tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
          "c": tableName === "Table 3" ? headerMapping?.length + index : headerMapping?.length + index + 1,
          "rs": 1,
          "cs": 4
        }
        return {
          r: tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 3 : basedata[basedata?.length - 1]?.r + 2,
          rs: 1,
          c: tableName === "Table 3" ? headerMapping?.length + index : headerMapping?.length + index + 1,
          cs: 1,
          v: {
            ht: 0,
            ct: { fa: "General", t: "g" },
            m: item,
            v: item,
            ff: "\"Tahoma\"",
            fs: `${defaultFs}`,
            merge: null,
            bg: "rgb(139,173,212)",
            tb: '2',
            w: 55,
          }
        }
      }
      else {
        return {
          r: tableName === "Table 3" ? basedata[basedata?.length - 1]?.r + 4 : basedata[basedata?.length - 1]?.r + 3,
          rs: 1,
          c: tableName === "Table 3" ? headerMapping?.length + index - 1 : headerMapping?.length + index,
          cs: 1,
          v: {
            ct: { fa: "General", t: "g" },
            m: item,
            v: item,
            ff: "\"Tahoma\"",
            fs: `${defaultFs}`,
            merge: null,
            bg: "rgb(139,173,212)",
            tb: '2',
            w: 55,
          }
        }
      }
    });

    if (tableName === "Table 3") {
      apiDataConfig.demo.config.merge[`${lobRowStartIdx}_${1}`] = {
        "r": lobRowStartIdx,
        "c": 1,
        "rs": 1,
        "cs": lobRowEndIdx - 1,
      }
    }

    let cellDataValuesMap = [];
    let rowIndex = varainceHeaderRows[varainceHeaderRows.length - 1]?.r + 1;
    tableTemplateData.map((item, cIndex) => {
      let rowHeight = 25;
      header.map((key, rIndex) => {
        rowIndex = cellDataValuesMap?.length == 0 ? rowIndex : cellDataValuesMap?.length > 0 && rIndex == 0 ? cellDataValuesMap[cellDataValuesMap.length - 1]?.r + 1 : cellDataValuesMap[cellDataValuesMap.length - 1]?.r;
        let text = item[key]?.toString()?.split('~~');
        let ss = [];
        function escapeRegExp(str) {
          return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
        }

        function splitWordsWithComma(array) {
          if (!array || array.length === 0) {
            return [];
          }
          let newArray = [];

          array.forEach((word) => {
            // Check if the word ends with a comma
            word = word.trim();
            if (word.endsWith(',')) {
              const wordWithoutComma = word.slice(0, -1).trim();
              // Add the word without the comma as a separate character, excluding leading spaces
              if (wordWithoutComma !== '') {
                newArray.push(wordWithoutComma);
              }
              newArray.push(',');
            } else if (word.includes('(') && word.includes(')')) {
              // If the word contains both '(' and ')', split them into separate characters
              const openingParen = word.indexOf('(');
              const closingParen = word.indexOf(')');
              const beforeParen = word.slice(0, openingParen);
              const insideParen = word.slice(openingParen + 1, closingParen);
              const afterParen = word.slice(closingParen + 1);
              if (beforeParen !== '') {
                newArray.push(beforeParen);
              }
              newArray.push('(');
              if (insideParen !== '') {
                newArray.push(insideParen);
              }
              newArray.push(')');
              if (afterParen !== '') {
                newArray.push(afterParen);
              }
            } else if (word.includes('(')) {
              // If the word contains an open parenthesis, split it into separate characters
              const openingParen = word.indexOf('(');
              const beforeParen = word.slice(0, openingParen);
              const insideParen = word.slice(openingParen + 1);
              if (beforeParen !== '') {
                newArray.push(beforeParen);
              }
              newArray.push('(');
              if (insideParen !== '') {
                newArray.push(insideParen);
              }
            } else if (word.includes(')')) {
              // If the word contains a closing parenthesis, split it into separate characters
              let closingParen = word.indexOf(')');
              let insideParen = word.slice(0, closingParen).trim();
              const afterParen = word.slice(closingParen + 1);
              if (insideParen !== '') {
                newArray.push(insideParen);
              }
              newArray.push(')');
              if (afterParen !== '') {
                newArray.push(afterParen);
              }
            } else {
              // If no comma, just add the word to the new array
              newArray.push(word);
            }
          });

          return newArray;
        }
        if (text && text?.length > 0) {
          text?.map((e, splitIndex) => {
            if (e?.toLowerCase().includes('page #')) {
              ss.push({
                "ff": "\"Tahoma\"",
                "fc": "rgb(68, 114, 196)",
                "fs": `${defaultFs}`,
                "cl": 0,
                "un": 0,
                "bl": 0,
                "it": 0,
                "v": e.trim() + "\r\n"
              });
            }
            else if (key.includes('Quote') && !item["BaseDocument"]?.toLowerCase()?.replace(/\\r\\n/g, '')?.includes("details not available in the document")) {
              if (!e.includes('Details not available in the document')) {
                let ptpSplitArray = e?.split(" ");
                let ctpSplitArray = item["BaseDocument"]?.split('~~')[splitIndex]?.split(" ");
                const ptpFlattenedArray = splitWordsWithComma(ptpSplitArray);
                const ctpFlattenedArray = splitWordsWithComma(ctpSplitArray);
                ctpFlattenedArray && ctpFlattenedArray?.length > 0 && ptpFlattenedArray.forEach((ptpe) => {
                  let css = "#000000";
                  let ctpText = ctpFlattenedArray.join(" ");
                  if (ptpe.includes("$||") || ptpe.includes("||") || ptpe.includes("(") || ptpe.includes(")")) {
                    css = "#000000";
                  } else {
                    let pattern = new RegExp(`\\b${escapeRegExp(ptpe.trim())}\\b`, 'i');
                    const ctpWordsArray = ctpText.split(' ');
                    // Check if each word in ptpe is present in ctpWordsArray
                    ptpe.split(' ').forEach((word) => {
                      if (!ctpWordsArray.includes(word.trim())) {
                        css = "#ff0000";
                      }
                    });
                    if (!pattern.test(ctpText)) {
                      css = "#ff0000";
                    }
                  }
                  ss.push({
                    "ff": "\"Tahoma\"",
                    "fc": css,
                    "fs": `${defaultFs}`,
                    "cl": 0,
                    "un": 0,
                    "bl": 0,
                    "it": 0,
                    "v": ptpe.trim() + " "
                  });
                });
              } else {
                ss.push({
                  "ff": "\"Tahoma\"",
                  "fc": "#000000",
                  "fs": `${defaultFs}`,
                  "cl": 0,
                  "un": 0,
                  "bl": 0,
                  "it": 0,
                  "v": e.trim() + "\r\n"
                });
              }
            }
            else {
              ss.push({
                "ff": "\"Tahoma\"",
                "fc": "#000000",
                "fs": `${defaultFs}`,
                "cl": 0,
                "un": 0,
                "bl": 0,
                "it": 0,
                "v": e.trim() + "\r\n"
              });
            }
          })
        };
        cellDataValuesMap.push({
          r: rowIndex,
          c: rIndex + 1,
          v: {
            ct: { fa: "General", t: "inlineStr", s: ss },
            merge: null,
            w: 55,
            tb: '2',
          }
        });
        if (text && rowHeight < parseInt(item[key]?.length / 3 + 30)) {
          rowHeight = parseInt(item[key]?.length / 3 + 30);
          apiDataConfig.demo.config.rowlen[`${rowIndex}`] = rowHeight;
        }
        if (rIndex == 0) {
          apiDataConfig.demo.config.rowlen[`${rowIndex}`] = rowHeight;
        }
      })
    });

    apiDataConfig.demo.config.borderInfo.push({
      "rangeType": "range",
      "borderType": "border-all",
      "color": "#000",
      "style": "1",
      "range": [
        {
          "left": 74,
          "width": 300,
          "top": 470,
          "height": 42,
          "left_move": 74,
          "width_move": 4213,
          "top_move": 471,
          "height_move": 1107,
          "row": [
            headerMapping[0]?.r,
            cellDataValuesMap[cellDataValuesMap?.length - 1]?.r
          ],
          "column": [
            headerMapping[0]?.c,
            varainceHeaderRows[varainceHeaderRows?.length - 1]?.c
          ],
          "row_focus": headerMapping[0]?.r,
          "column_focus": headerMapping[0]?.c
        }
      ]
    });

    varainceHeaderRows.forEach(row => {
      if (row?.v && row?.v?.m && typeof row?.c === 'number' && row.v.m !== 'Action from Variances by MMA') {
        tableColumnNames[row.v.m] = row?.c;
      }
    });

    headerMapping = [...headerMapping, ...varainceHeaderRows, ...cellDataValuesMap];

    const allCellDatas = [...headerMapping];
    allCellDatas.sort((a, b) => a.r - b.r);

    if (allCellDatas && allCellDatas?.length > 0) {
      const rangeColumnDetails = quoteTableColumnDetails;
      rangeColumnDetails[tableData?.Tablename] = { "columnNames": tableColumnNames, "range": { "start": allCellDatas[0]?.r, "end": allCellDatas[allCellDatas?.length - 1]?.r } }
      setQuoteTableColumnDetails(rangeColumnDetails);
    }

    basedata.push(...allCellDatas);
    apiDataConfig.demo.celldata = basedata;
  };

  const updateAndExportCall = async (apiFlagCall) => {
    // update & exportExcel functionality data structuring and handling here
    let sheetData = luckysheet?.getSheet()?.data;
    const tableNamesList = Object.keys(quoteTableColumnDetails);
    if (apiFlagCall == true) {
      let dataToAddOrUpdate = [];
      const dbIds = jobData?.map((e) => e?.ID)?.sort((a, b) => a - b);
      let groupId = 1;
      if (tableNamesList && tableNamesList?.length > 0 && sheetData?.length > 0) {
        tableNamesList.forEach(async (f, index) => {
          if (f != "Table 1") {
            let columnName = quoteTableColumnDetails[f]?.columnNames;
            const rowStart = ((f === "Table 3" || f === "Table 1") ? (quoteTableColumnDetails[f]?.range?.start + 3) : (quoteTableColumnDetails[f]?.range?.start + 2));
            const rowEnd = quoteTableColumnDetails[f]?.range?.end;
            const splittedData = sheetData.slice(rowStart, (rowEnd + 1));

            splittedData?.forEach((item, iIndex) => {
              // need to handle PT vaiances like Quote. on hold for now. base will alwasy be the PT
              const pageNums = getText(item[columnName["PageNumber"]], true);
              const pageNumMapping = {};

              // Parse 'Q1: 1, Q2: 1...etc' into an object like { "Q1": 1, "Q2": 1 ....} dynamically
              pageNums?.split('; ')?.forEach(pair => {
                const [key, value] = pair.split(/:\s?/).map(str => str.trim());
                pageNumMapping[key] = value !== "" ? value : null;
              });

              fileState?.forEach((file, fIndex) => {
                const variance = {};
                const tbl4KeyMapping = {
                  "Quote 1": "Quote 1 - Listed",
                  "Quote 2": "Quote 2 - Listed",
                  "Quote 3": "Quote 3 - Listed",
                  "Quote 4": "Quote 4 - Listed",
                  "Quote 5": "Quote 5 - Listed"
                };

                let docColKey;
                if (f === "Table 4") {
                  docColKey = tbl4KeyMapping[file["Key"]] || tbl4KeyMapping[file["ptpKey"]];
                } else {
                  docColKey = columnName[file["Key"]] || columnName[file["ptpKey"]]
                }

                variance["CoverageSpecification"] = getText(item[columnName["CoverageSpecification"]], true);
                variance["ChecklistQuestion"] = getText(item[columnName["ChecklistQuestion"]], true);
                variance["IsBaseDocument"] = (fIndex === 0);
                variance["ExtractedValue"] = f === "Table 4" ? getText(item[columnName[docColKey]], true) : getText(item[docColKey], true);
                const pageNoValue = pageNumMapping[file["key"]]?.split(';')[0] || pageNumMapping[file["ptpkey"]]?.split(';')[0];
                variance["PageNum"] = ((pageNoValue != null || pageNoValue != undefined) ? Number(pageNoValue) : null);
                variance["DocumentName"] = file["FileName"];
                variance["DocID"] = file["DocID"] == null ? "" : (file["DocID"] || "");
                variance["GroupID"] = groupId;
                variance["CreatedBy"] = sessionStorage.getItem('userName');

                dataToAddOrUpdate.push(variance);
              });

              dataToAddOrUpdate.forEach(row => {
                const matchedScoreVal = jobData?.find(rowitem => rowitem?.DocumentName === row?.DocumentName && rowitem.ChecklistQuestion === row.ChecklistQuestion);
                if (matchedScoreVal) {
                  row["MatchScore"] = matchedScoreVal?.MatchScore;
                } else {
                  row["MatchScore"] = null;
                }
              });
              groupId = groupId + 1;
            });
          } else {
            //handle header part;
          }
        });
      }

      if (dbIds?.length > 0) {
        dataToAddOrUpdate = dataToAddOrUpdate?.map((e, index) => {
          e["ID"] = dbIds[index] || 0;
          return e;
        });
      } else {
        dataToAddOrUpdate = dataToAddOrUpdate?.map((e, index) => {
          e["ID"] = 0;
          return e;
        });
      };

      const paramsData = jobDetails;
      dataToAddOrUpdate = dataToAddOrUpdate?.map(item => ({
        ...item,
        JobID: Number(paramsData.Id)
      }));
      await saveCall(paramsData?.JobId, "FormTable", JSON.stringify(dataToAddOrUpdate), paramsData?.BrokerId, paramsData?.Id);
    } else if (apiFlagCall == false) {
      if (tableNamesList && tableNamesList?.length > 0 && sheetData?.length > 0) {
        let exportDataSet = [];
        const queryParams = new URLSearchParams(location.search);
        const jobId = queryParams.get("jobId");
        const id = queryParams.get("id");
        const tb1ExportData = [];
        const table1Data = [];
        let documnetname = fileState;
        const quoteFiltes = documnetname?.filter((f) => f?.Column === "qc")?.map(item => item?.FileName);
        const priorColFile = documnetname?.filter((f) => f?.Column === "ptp")?.map(item => item?.FileName);

        tableNamesList.forEach(async (f) => {
          const rowStart = (f === "Table 3" || f === "Table 1") ? (quoteTableColumnDetails[f]?.range?.start + 3) : (quoteTableColumnDetails[f]?.range?.start + 2);
          const rowEnd = quoteTableColumnDetails[f]?.range?.end;
          const splittedData = sheetData.slice(rowStart, rowEnd + 1);
          const parseData = JSON.parse(sessionStorage.getItem('lobValueTbl3'));
          const table3Lob = parseData[0];

          if (f != "Table 1") {
            let sampleSet = splittedData?.map((row, rowIndex) => {
              const dataSet = {
                CoverageSpecification:
                  row[quoteTableColumnDetails[f]?.columnNames["CoverageSpecification"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["CoverageSpecification"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["CoverageSpecification"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                ChecklistQuestion:
                  row[quoteTableColumnDetails[f]?.columnNames["ChecklistQuestion"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["ChecklistQuestion"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["ChecklistQuestion"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                Status:
                  row[quoteTableColumnDetails[f]?.columnNames["Status"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Status"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Status"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                DocumentViewer:
                  row[quoteTableColumnDetails[f]?.columnNames["Status"] + 1] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Status"] + 1]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Status"] + 1]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                ActionsOnVariances:
                  row[quoteTableColumnDetails[f]?.columnNames["Actions on Variances"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Actions on Variances"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Actions on Variances"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                RequestEndorsement:
                  row[quoteTableColumnDetails[f]?.columnNames["Request Endorsement"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Request Endorsement"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Request Endorsement"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                Notes:
                  row[quoteTableColumnDetails[f]?.columnNames["Notes"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Notes"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Notes"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                NotesFreeFill:
                  row[quoteTableColumnDetails[f]?.columnNames["Notes (Free Fill)"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["Notes (Free Fill)"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["Notes (Free Fill)"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
                PageNumber:
                  row[quoteTableColumnDetails[f]?.columnNames["PageNumber"]] != null
                    ? row[quoteTableColumnDetails[f]?.columnNames["PageNumber"]]?.ct?.s?.[0]?.v?.replace(/\r\n/g, '') ??
                    row[quoteTableColumnDetails[f]?.columnNames["PageNumber"]]?.v?.replace(/\r\n/g, '') ?? ""
                    : "",
              };

              const pageNumbers = dataSet.PageNumber.split(';').map(page => page.trim()).filter(page => page);
              const pageNumberMap = {};
              pageNumbers.forEach(page => {
                const parts = page.split(':').map(part => part.trim());
                if (parts.length === 2) {
                  pageNumberMap[parts[0]] = parts[1]; // Map BD, Q1, Q2, Q3 to their respective numbers
                }
              });

              // Update file names with the corresponding page number
              Object.keys(quoteTableColumnDetails[f]?.columnNames).forEach(column => {
                if (column.startsWith("Quote")) {
                  const index = parseInt(column.split(" ")[1], 10) - 1;
                  const fileName = quoteFiltes[index] || column;
                  const pageNumber = pageNumberMap[`Q${index + 1}`] ? `${pageNumberMap[`Q${index + 1}`]}` : "";
                  const value = row[quoteTableColumnDetails[f]?.columnNames[column]]?.v
                    ?? (Array.isArray(row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s)
                      ? row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s?.map(e => e.v).join('')?.replace(/\r\n/g, '')
                      : row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s[0]?.v)?.replace(/\r\n/g, '')
                    ?? "Details not available in the document";
                  dataSet[fileName] = value === "Details not available in the document" ? value : value + " ~~Page # " + pageNumber;
                } else if (column.startsWith("Base")) {
                  const idx = 0;
                  const ptpFileName = priorColFile[idx];
                  const pageNumber = pageNumberMap[`BD${idx + 1}`] ? `${pageNumberMap[`BD${idx + 1}`]}` : "";
                  const value = row[quoteTableColumnDetails[f]?.columnNames[column]]?.v ??
                    (Array.isArray(row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s)
                      ? row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s?.map(e => e.v).join('')?.replace(/\r\n/g, '')
                      : row[quoteTableColumnDetails[f]?.columnNames[column]]?.ct?.s[0]?.v)?.replace(/\r\n/g, '')
                    ?? "Details not available in the document"
                  dataSet[ptpFileName] = value === "Details not available in the document" ? value : value + " ~~Page # " + pageNumber;
                }
              });
              return dataSet;
            });

            const docFiles = fileState.map(file => file?.FileName);

            let matchedRecordes = sampleSet?.filter((e) => (e?.Status?.toLowerCase() === "matched" || e?.Status?.toLowerCase() === "match") && docFiles.some((key) => {
              const fileContent = e[key]?.split("~~")[0]?.trim().toLowerCase();
              return fileContent !== "details not available in the document";
            }));
            let misMatchedRecordes = sampleSet?.filter((e) => e?.Status?.toLowerCase() === "mismatch" && docFiles.some((key) => {
              const fileContent = e[key]?.split("~~")[0]?.trim().toLowerCase();
              return fileContent !== "details not available in the document";
            }));
            let dNARecordes = sampleSet?.filter((e) => docFiles.every((key) => {
              const fileContent = e[key]?.split("~~")[0]?.trim().toLowerCase();
              return fileContent === "details not available in the document";
            }));

            if (f == "Table 2" || f == "Table 3" || f == "Table 4") {
              if (misMatchedRecordes && misMatchedRecordes?.length > 0) {
                misMatchedRecordes = misMatchedRecordes.map(item => ({
                  ...item,
                  PolicyLob: f == "Table 2" ? "Common Declaration" : f == "Table 3" ? table3Lob : f == "Table 4" ? "Forms listed" : ""
                }));
                exportDataSet.push({
                  TableName: `Quote${f}`,
                  Id: jobId,
                  jobId: id,
                  Data: JSON.stringify(misMatchedRecordes),
                });
              };

              if (matchedRecordes && matchedRecordes?.length > 0) {
                matchedRecordes = matchedRecordes.map(item => ({
                  ...item,
                  PolicyLob: "Matched Only"
                }));
                exportDataSet.push({
                  TableName: "MatchedOnlyTable",
                  Id: jobId,
                  jobId: id,
                  Data: JSON.stringify(matchedRecordes),
                });
              };

              if (dNARecordes && dNARecordes?.length > 0) {
                dNARecordes = dNARecordes.map(item => ({
                  ...item,
                  PolicyLob: "Details not available"
                }));
                exportDataSet.push({
                  TableName: "DetailsnotavailableTable",
                  Id: jobId,
                  jobId: id,
                  Data: JSON.stringify(dNARecordes),
                });
              };
            };
          } else if (f == "Table 1") {
            const paramasData = jobDetails;
            const jobid = paramasData?.JobId;
            const nullFiter = splittedData.map(sublist => sublist.filter(item => item !== null));
            const result = nullFiter.map(([index1, index2]) => ({
              index1,
              index2,
            }));

            for (let row in result) {
              const cellData = result[row];
              const cell1Text = cellData.index1?.v || cellData.index1?.ct || '';
              const cell2Text = cellData.index2?.v !== undefined ? (cellData.index2.v || cellData.index2?.ct) : (cellData.index2?.ct?.fa === "@" ? "" : cellData.index2?.ct?.fa);

              if (cell1Text && cell1Text.s && cell1Text.s.length > 0 && cell2Text || cell1Text) {
                const headerValue = Array.isArray(cell1Text.s) ? cell1Text.s.map(item => item.v || '').join(',') : cell1Text;
                const concatenatedValues = Array.isArray(cell2Text.s) ? cell2Text.s.map(item => item.v).join('') : cell2Text;

                const objStructure = {
                  HeaderID: row,
                  JOBID: jobid,
                  'PolicyLob': table3Lob,
                  Headers: headerValue,
                  "NoColumnName": concatenatedValues,
                };
                table1Data.push(objStructure);
              }
            }
            tb1ExportData.push({ "TableName": "QuoteTable 1", "Id": jobId, "jobId": id, Data: JSON.stringify(table1Data) });
          }
        });

        let DNA_items = exportDataSet?.filter(r => r?.TableName === "DetailsnotavailableTable")?.flatMap(r => JSON.parse(r?.Data));
        let Matched_items = exportDataSet?.filter(r => r?.TableName === "MatchedOnlyTable")?.flatMap(r => JSON.parse(r?.Data));

        let dna_found = false;
        let match_found = false;
        exportDataSet = exportDataSet?.filter(row => {
          if (row?.TableName === "DetailsnotavailableTable") {
            if (!dna_found) {
              dna_found = true;
              row.Data = JSON.stringify(DNA_items);
              return true;
            }
            return false;
          }
          return true;
        });

        exportDataSet = exportDataSet?.filter(row => {
          if (row?.TableName === "MatchedOnlyTable") {
            if (!match_found) {
              match_found = true;
              row.Data = JSON.stringify(Matched_items);
              return true;
            }
            return false;
          }
          return true;
        });

        let tablearray = ["QuoteTable 2", "QuoteTable 3", "QuoteTable 4"];

        let staticData = [{
          "CoverageSpecification": "",
          "ChecklistQuestion": "",
          "Status": "",
          "DocumentViewer": "",
          "ActionsOnVariances": "",
          "RequestEndorsement": "",
          "Notes": "",
          "NotesFreeFill": "",
          "PageNumber": "",
        }];

        let docColumns = fileState?.map(f => f?.FileName);
        docColumns.forEach(key => {
          staticData[0][key] = ""
        })

        tablearray?.forEach((table) => {
          const tablePresent = exportDataSet?.some(tbl => tbl?.TableName === table);
          const parseData = JSON.parse(sessionStorage.getItem('lobValueTbl3'));
          const table3Lob = parseData[0];

          const policyMap = staticData.map(item => ({
            ...item,
            PolicyLob: table === "QuoteTable 2" ? "Common Declaration" : table === "QuoteTable 3" ? table3Lob : table === "QuoteTable 4" ? "Forms listed" : "",
          }));

          if (!tablePresent) {
            exportDataSet.push({
              TableName: table,
              Id: jobId,
              jobId: id,
              Data: JSON.stringify(policyMap)
            });
          }
        });

        const desiredOrder = [
          "QuoteTable 2",
          "QuoteTable 3",
          "QuoteTable 4",
          "MatchedOnlyTable",
          "DetailsnotavailableTable"
        ];

        exportDataSet.sort((a, b) => {
          return desiredOrder.indexOf(a?.TableName) - desiredOrder.indexOf(b?.TableName);
        });

        const findingTable2Index = exportDataSet?.findIndex(table => table?.TableName === "QuoteTable 2" || (table?.TableName === "MatchedOnlyTable" || table?.TableName === "DetailsnotavailableTable"));
        if (findingTable2Index !== -1) {
          exportDataSet.splice(findingTable2Index, 0, tb1ExportData[0]);
          let formTableData = [];
          exportDataSet.forEach(item => {
            if (item.TableName === "QuoteTable 1") {
              formTableData.push(item.TableName);
            }
          });
          // const formTableDataJson = '"' + JSON.stringify(formTableData) + '"';
          const formTableDataJson = "[\"QuoteTable\"]"
          await exportExcelData(exportDataSet, formTableDataJson);
        }
      }
    }
  };


  const exportExcelData = async (Tabledata, TableNames) => {
    document.body.classList.add('loading-indicator');
    const Token = await processAndUpdateToken(token);
    const headers = {
      'Authorization': `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/Excel/ExportExcel`;
    try {
      const response = await axios({
        method: "POST",
        url: apiUrl,
        headers: headers,
        data: {
          Data: TableNames,
          Tabledata: Tabledata
        },
        responseType: 'blob'
      });
      if (response.status !== 200) {
        return "error";
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Tabledata[0].Id}Qc.xlsx`);
      document.body.appendChild(link);
      link.click();
      return "success";
    } catch (error) {
      return "error";
    } finally {
      document.body.classList.remove('loading-indicator');
      // return "success";
    }
  };

  const saveCall = async (JobId, TableName, apiData, BrokerId, Id) => {    // update function call 
    document.body.classList.add('loading-indicator');
    const Token = await processAndUpdateToken(token);
    const headers = {
      'Authorization': `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/QuoteCompare/UpdateChecklistData`;
    try {
      const response = await axios({
        method: "POST",
        url: apiUrl,
        headers: headers,
        data: {
          JobId: JobId,
          TableName: TableName,
          NewTemplateData: apiData,
          BrokerID: BrokerId,
        }
      });
      if (response.status !== 200) {
        container.current.showSnackbar('Error fetching data', "error", true);
        return "error";
      };

      if (response?.status == 200) {
        container.current.showSnackbar('Data updation is processing for saveData', "info", true);
        await stateUpdateFunCall(Id, BrokerId);
      };
      setTimeout(() => {
        container.current.showSnackbar('Data updated successfully', "success", true);
      }, 500);
      return response.data;
    } catch (error) {
      // console.error( 'Error:', error );
      return "error";
    } finally {
      document.body.classList.remove('loading-indicator');
      return "success";
    }
  };

  const stateUpdateFunCall = async (id, brokerId) => {                   // Function for jobData --> state updation after every data save
    try {
      const jobQCDataStateUpdate = await GetJobQCData(id, brokerId);
      container.current.showSnackbar('saved data fetched successfully', "success", true);
      SetJobData(jobQCDataStateUpdate);
    } catch (error) {
      console.error("Error rendering job data:", error);
      container.current.showSnackbar('Error in fetching the recent updatedData', "error", true);
    }
  };

  const matchedOrUnMatchedFilter = (rowIndex) => {
    if (rowIndex === 6 || rowIndex === 4 || rowIndex === 5) {
      const sessionData = sessionStorage.getItem("dataSet");
      let tblData = JSON?.parse(sessionData);
      const tableColumnDetails = quoteTableColumnDetails;
      let recordsToHide = [];

      tblData?.forEach((table) => {
        if (table?.Tablename != "Table 1") {
          const tableConfig = tableColumnDetails[table?.Tablename];
          const { columnNames, range } = tableConfig;

          const quoteColumns = Object.keys(columnNames)?.filter(key => key?.startsWith("Quote"));
          table?.TemplateData?.forEach((row, index) => {
            let matches = false;

            if (rowIndex === 4) {   //filter Matched rowItems
              matches = ["BaseDocument", ...quoteColumns]?.every(key => row[key]?.trim()?.toLowerCase() === "matched");
            } else if (rowIndex === 6) {   //filter Details not available in the document rowItems
              matches = ["BaseDocument", ...quoteColumns]?.every(key => row[key]?.trim()?.toLowerCase() === "details not available in the document");
            } else if (rowIndex === 5) {   //filter variances rowItems
              matches = ["BaseDocument", ...quoteColumns]?.some(key => row[key]?.trim()?.toLowerCase() !== "matched" && row[key]?.trim()?.toLowerCase() !== "details not available in the document");
            }

            if (!matches) {
              recordsToHide.push(range?.start + index + (table?.Tablename === "Table 3" ? 4 : 3));
            }
          });
        }
      });
      if (recordsToHide?.length > 0) {
        recordsToHide = groupNumbers(recordsToHide);
      }
      showOrHideRecords(recordsToHide, rowIndex);
    } else {
      showOrHideRecords([]);
      dataGrouping(0);
    }
  };

  let shownRowGroups = new Set();
  const rowSymbols = new Map();
  const dataGrouping = (ToBeShownIndex) => {
    let colRecordTect = '';
    if (ToBeShownIndex > 0) {
      const rowRecord = luckysheet.getcellvalue(ToBeShownIndex);
      const columnRecord = rowRecord[0];
      colRecordTect = getText(columnRecord, false);
      if (colRecordTect != "-" && colRecordTect != "+") {
        return;
      }
    }

    const sessionData = sessionStorage.getItem("dataSet");
    let checklistData = JSON?.parse(sessionData);

    const tableDetails = quoteTableColumnDetails;
    let keys = Object.keys(tableDetails)?.filter((f) => f !== "Table 1");
    let recordsToHide = [];
    keys.forEach((f) => {
      const tableConfigData = tableDetails[f];
      const recordRange = tableConfigData?.range;
      const shortQuestion = [];
      const findData = checklistData.find((fi) => fi?.Tablename == f);
      if (findData && findData?.TemplateData?.length > 0) {
        const backUpTemplateData = findData?.TemplateData;

        let needIgnorance = false;
        const ignoranceShortCode = [];
        if (ToBeShownIndex > 0 && recordRange?.start < ToBeShownIndex && recordRange?.end >= ToBeShownIndex) {
          needIgnorance = true;
        }
        const ignoreQCSet = [];
        findData?.TemplateData.forEach((item, itemIndex) => {
          let questionText = item["ChecklistQuestion"];
          if (questionText) {
            questionText = questionText?.toUpperCase()?.trim();
            const questionCode = questionText?.substring(0, 3);

            let isIgnorancecheck = false;
            if (needIgnorance) {
              isIgnorancecheck = ((recordRange?.start + itemIndex + (f == "Table 3" ? 3 : 2)) === ToBeShownIndex) && colRecordTect == "+";
              if (isIgnorancecheck) {

                ignoranceShortCode.push(questionCode);
              }
            } else if (ToBeShownIndex && ToBeShownIndex > 0) {
              // ignoreQCSet, isIgnorancecheck
              if (questionCode && !ignoreQCSet?.includes(questionCode)) {
                let colRecordText1 = '';
                const rowRecord1 = luckysheet.getcellvalue(recordRange?.start + itemIndex + (f == "Table 3" ? 3 : 2));
                const columnRecord1 = rowRecord1[0];
                colRecordText1 = getText(columnRecord1, false);
                if (colRecordText1 == "-") {
                  ignoreQCSet.push(questionCode);
                }
              }
            }
            if (shortQuestion?.includes(questionCode)) {
              if ((!ignoranceShortCode || (ignoranceShortCode && ignoranceShortCode?.length === 0)) && (ignoreQCSet?.length === 0)) {
                recordsToHide.push(recordRange?.start + itemIndex + (f === "Table 3" ? 4 : 3));
              }
              else if (!ignoranceShortCode?.includes(questionCode) && !ignoreQCSet?.includes(questionCode)) {
                recordsToHide.push(recordRange?.start + itemIndex + (f === "Table 3" ? 4 : 3));
              }
            } else {
              const hasMoreThanOne = backUpTemplateData.filter((qcF) => {
                const cqTextForFilter = qcF["ChecklistQuestion"];
                if (cqTextForFilter) {
                  const cqFormatFixText = cqTextForFilter?.toUpperCase()?.trim();
                  if (cqFormatFixText?.substring(0, 3) == questionCode) {
                    return qcF;
                  }
                }
              });
              if (hasMoreThanOne && hasMoreThanOne?.length > 1) {
                const dataToPopulate = {
                  "m": ignoreQCSet?.includes(questionCode) ? "-" : ToBeShownIndex && ToBeShownIndex > 0 && isIgnorancecheck ? colRecordTect == "-" ? "+" : "-" : "+",
                  "ct": {
                    "fa": "General",
                    "t": "g"
                  },
                  "v": ignoreQCSet?.includes(questionCode) ? "-" : ToBeShownIndex && ToBeShownIndex > 0 && isIgnorancecheck ? colRecordTect == "-" ? "+" : "-" : "+",
                  "ht": "0",
                  "fs": "10",
                  "fc": "#000000",
                  "bl": 1
                };

                const currentRow = recordRange.start + itemIndex + (f === "Table 3" ? 3 : 2);
                // luckysheet.setcellvalue( recordRange?.start + itemIndex + ( f === "Table 3" ? 3 : 2), 0, luckysheet.flowdata(), dataToPopulate );
                setCellValue(currentRow, 0, dataToPopulate);
              }
              shortQuestion.push(questionCode);
            }
          }
        })
      }
    });
    if (recordsToHide?.length > 0) {
      recordsToHide = groupNumbers(recordsToHide);
    }

    // showOrHideRecords(recordsToHide);
    showOrHideRecords(recordsToHide, ToBeShownIndex, colRecordTect);
  };

  const showOrHideRecords = (rowSet, rowIndex, symbol) => {
    const config = luckysheet.getConfig();
    const hiddenRows = config?.rowhidden ? Object.keys(config?.rowhidden) : [];

    if (hiddenRows && hiddenRows?.length > 0) {
      const parsedSet = hiddenRows.map((f) => parseInt(f));
      const grouppedSet = groupNumbers(parsedSet);
      grouppedSet.forEach((f) => {
        luckysheet.showRow(f[0], f[f?.length - 1]);
      });

      luckysheet.scroll({
        targetRow: rowIndex,
        targetColumn: 0
      });
    }

    const updateSymbol = (rowIndex, symbol) => {
      const dataToPopulate = {
        "m": symbol,
        "ct": {
          "fa": "General",
          "t": "g"
        },
        "v": symbol,
        "ht": "0",
        "fs": "10",
        "fc": "#000000",
        "bl": 1
      };

      setCellValue(rowIndex, 0, dataToPopulate);
    };


    if (symbol != undefined) {
      if (rowSet?.length > 0) {
        rowSet.forEach((f) => {
          const startRowIndex = f[0] - 1;
          const endRowIndex = f[f.length - 1] - 1;
          const currentSymbol = rowSymbols.get(rowIndex) || "+";

          // If symbol is "+"
          if (symbol === "+") {
            if (shownRowGroups.has(startRowIndex)) {
              luckysheet.showRow(startRowIndex, endRowIndex);
            } else {
              luckysheet.hideRow(startRowIndex, endRowIndex);
            }
            if (f.includes(startRowIndex + 1) && rowIndex != undefined) {
              shownRowGroups.add(rowIndex + 1);
              rowSymbols.set(rowIndex, "-");
            }
          }
          // If symbol is "-"
          else if (symbol === "-") {
            if ((symbol === "-" && shownRowGroups.has(rowIndex)) || currentSymbol === "-") {
              luckysheet.hideRow(startRowIndex, endRowIndex);
              shownRowGroups.delete(rowIndex + 1);
              rowSymbols.set(rowIndex, "+");
            } else if (!shownRowGroups.has(startRowIndex)) {
              luckysheet.hideRow(startRowIndex, endRowIndex);
            }
          }

          // If rowIndex is undefined, hide rows based on startRowIndex
          else if (f?.length > 0 && rowIndex === undefined) {
            if (!shownRowGroups?.has(startRowIndex)) {
              luckysheet?.hideRow(startRowIndex, endRowIndex);
            }
          }

          else if (f?.length > 0 && rowIndex != undefined && symbol === "") {
            luckysheet.hideRow(startRowIndex, endRowIndex);
          }

          // Ensure previously expanded rows keep "-" symbol and hide them if necessary
          rowSymbols.forEach((value, key) => {
            if (shownRowGroups.has(key + 1) && rowIndex !== key) {
              // Hide previously shown rows when switching to a new rowIndex
              rowSymbols.set(key, "-");
              updateSymbol(key, "-");
            }
          });
        });

        luckysheet.scroll({
          targetRow: rowIndex,
          targetColumn: 0
        });
      }
    } else {
      if (rowSet?.length > 0) {
        rowSet.forEach((f) => {
          if (f?.length > 0) {
            luckysheet.hideRow((f[0] - 1), (f[f?.length - 1] - 1));
          } else {
            luckysheet.hideRow((f[0] - 1), (f[f?.length - 1] - 1));
          }
        });
        luckysheet.scroll({
          targetRow: rowIndex,
          targetColumn: 0
        });
      }
    }
  };

  // Function to handle cell selection and update values
  let previousCell = { row: null, col: null, text: 'string' };
  const handleCellSelection = async (range, flagCheck) => {
    if (flagCheck === "Quote Compare") {
      if (range && range[0]?.row && range[0]?.column && range[0]?.row[0] === range[0]?.row[1] &&
        range[0]?.column[0] === range[0]?.column[1] && [3, 4, 5, 6].includes(range[0]?.row[0]) &&
        range[0]?.column[0] === 4) {

        const currentRow = range[0]?.row[0];
        const currentCol = 4;

        let getRowValue = luckysheet.getcellvalue(currentRow);
        const objectAtIndex4 = getRowValue[4];
        if (objectAtIndex4?.ct?.s && Array.isArray(objectAtIndex4?.ct?.s)) {
          let varianceText = objectAtIndex4?.ct?.s[1]?.v
          if (varianceText === 'Matched' || varianceText === 'All Variances' || varianceText === 'Full Variances' || varianceText === 'Variances' || varianceText === 'Details not available in the document') {
            const revertBoxCode = {
              "ct": {
                "fa": "General",
                "t": "inlineStr",
                "s": [
                  {
                    "fs": "10",
                    "v": "■ "
                  },
                  {
                    "vt": "0",
                    "ht": "1",
                    "fs": "10",
                    "un": 0,
                    "bl": 1,
                    "fc": "#0000ff",
                    "ff": "\"Tahoma\"",
                    "m": varianceText,
                    "v": varianceText
                  }
                ]
              },
              "merge": null,
              "w": 55,
              "tb": "2",
              "fc": "#0000ff",
              "fs": "10"
            }

            // Update the current cell with revertBoxCode
            setCellValue(currentRow, currentCol, revertBoxCode);

            // Restore the previous cell with previouscode if there was a previous selection
            if (previousCell.row !== null && previousCell.col !== null && previousCell.text !== undefined) {
              const previousBoxcode = {
                "ct": {
                  "fa": "General",
                  "t": "inlineStr",
                  "s": [
                    {
                      "fs": "10",
                      "v": "□ "
                    },
                    {
                      "vt": "0",
                      "ht": "1",
                      "fs": "10",
                      "un": 0,
                      "bl": 1,
                      "fc": "#0000ff",
                      "ff": "\"Tahoma\"",
                      "m": previousCell.text,
                      "v": previousCell.text
                    }
                  ]
                },
                "merge": null,
                "w": 55,
                "tb": "2",
                "fc": "#0000ff",
                "fs": "10"
              }
              setCellValue(previousCell.row, previousCell.col, previousBoxcode);
            }

            // Update the previousCell to the current cell
            previousCell.row = currentRow;
            previousCell.col = currentCol;
            previousCell.text = varianceText
          }
        }
      }
    }
  };

  const setCellValue = (row, column, data) => {
    luckysheet.setcellvalue(row, column, luckysheet.flowdata(), data);
    luckysheet.jfrefreshgrid();
  };

  const renderLuckySheet = async () => {
    try {
      const options = {
        container: "luckysheet",
        showinfobar: false,
        showsheetbar: true,
        lang: "en",
        data: [apiDataConfig?.demo],
        enableAddRow: true,
        showtoolbar: true,
        row: 2,
        column: 3,
        allowUpdate: true,
        enableAddBackTop: true,
        sheetRightClickConfig: {
          delete: false,
          copy: false,
          rename: false,
          color: false,
          hide: false,
          move: false,
        },
        showsheetbarConfig: {
          add: false,
          menu: false,
        },
        showstatisticBar: true,
        hook: {
          workbookCreateAfter(json) {
            luckysheet.setSheetZoom(1);
          },
          rangeSelect: function (index, sheet) {
            const indexData = sheet;
            if (indexData && indexData?.length > 0) {
              if (indexData[0]?.row?.length > 0 && indexData[0]?.row[0] == indexData[0]?.row[1]) {
                setSelectedRowIned(indexData[0]?.row[0]);
                setHasMultipleRowsSelected(false);
              } else if (indexData[0]?.row?.length > 0 && indexData[0]?.row[0] != indexData[0]?.row[1]) {
                setSelectedRowIndexRange(indexData[0]?.row)
                setHasMultipleRowsSelected(true);
              } else {
                setSelectedRowIned(null);
                setHasMultipleRowsSelected(true);
                setSelectedRowIndexRange([]);
              }
            }
          },
          updated: function () {
            let range = luckysheet.getRange();
            document.onkeyup = function (e) {
              if (e.which === 46 || e.which === 8) {
                if (range && range.length > 0 && range !== undefined) {
                  let selectedRowIndex = range[0].row[0];  // Get the selected row index
                  let selectedColumnIndex = range[0].column[0];  // Get the selected column index
                  const tabledata = quoteTableColumnDetails;
                  const excludedColumns = ["columnid"];
                  const selectedTable = findTableForIndex(selectedRowIndex, tabledata, excludedColumns);
                  const tblSelectedRow = tabledata[selectedTable];
                  if (tblSelectedRow !== undefined) {
                    const colrange = range[0].column[0]
                    // Table 1 based on range start and end
                    if (selectedTable === "Table 1" && colrange === 1) {
                      const getRange = tabledata["Table 1"].range;
                      const rangeEnd = getRange.end;
                      const rangeStart = getRange.start;
                      if (selectedRowIndex >= rangeStart && selectedRowIndex <= rangeEnd) {
                        luckysheet.undo();
                      }
                    } else if (range && range?.length > 0) {
                      const r = range[0].row[0];
                      const c = range[0].column[0];
                      if (range[0]?.column[0] == range[0]?.column[1] && range[0]?.row[0] == range[0]?.row[1]) {
                        if (range[0].row[0] == r && c == 0) {
                          luckysheet?.undo();
                        }
                      }
                    } else {
                      // Table 3 has three header rows, others have two header rows
                      let headerStartRowIndex = tblSelectedRow?.range?.start;  // First header row (start)
                      let headerEndRowIndex;
                      if (selectedTable === "Table 3") {
                        headerEndRowIndex = headerStartRowIndex + 2;  // Three header rows for Table 3
                      } else {
                        headerEndRowIndex = headerStartRowIndex + 1;  // Two header rows for other tables
                      }
                      let columnNames = tblSelectedRow?.columnNames;
                      let isColumnName = false;
                      if (Array.isArray(columnNames)) {
                        isColumnName = columnNames.includes(luckysheet.getCellValue(selectedRowIndex, selectedColumnIndex));
                      } else if (typeof columnNames === "object") {
                        isColumnName = Object.values(columnNames).includes(selectedColumnIndex);
                      }
                      if (selectedRowIndex >= headerStartRowIndex && selectedRowIndex <= headerEndRowIndex && isColumnName) {
                        e.preventDefault();
                        luckysheet.undo();  // Revert delete action
                      }
                    }
                  }
                }
              }
            }
          },
          cellEditBefore(range) {
            let flagCheck = luckysheet?.getSheet()?.name;
            //code for variance columns options
            if (range && range.length > 0 && range != undefined) {
              if (flagCheck === "Quote Compare") {
                if (range && range[0]?.row && range[0]?.column && range[0]?.row[0] === range[0]?.row[1] &&
                  range[0]?.column[0] === range[0]?.column[1] && [3, 4, 5, 6].includes(range[0].row[0]) &&
                  range[0].column[0] === 4) {
                  setTimeout(() => {
                    luckysheet.exitEditMode();
                    matchedOrUnMatchedFilter(range[0]?.row[0]);
                    container.current.showSnackbar(range[0]?.row[0] === 4 ?
                      "Matched Records Filtered" : range[0]?.row[0] === 5 ? "Variances Records Filtered" :
                        range[0]?.row[0] === 6 ? "Details not available Questions filtered" : "Filter Removed", "info", true);
                  }, 100);


                  // checkbox setvalue for Variances columns
                  if (range && range.length > 0 && range != undefined) {
                    handleCellSelection(range, flagCheck);
                  }
                  return;
                }
                if (range && range[0]?.row && range[0]?.column && range[0]?.row[0] === range[0]?.row[1] &&
                  range[0]?.column[0] === range[0]?.column[1] && range[0].column[0] === 0) {
                  setTimeout(() => {
                    luckysheet.exitEditMode();
                    dataGrouping(range[0]?.row[0]);
                  }, 100);
                  return;
                }
              }
            }
          },
        },
        cellRightClickConfig: {
          insertRow: false,
          insertColumn: false,
          deleteRow: false,
          deleteColumn: false,
          deleteCell: false,
          clear: false,
          sort: false,
          filter: false,
          chart: false,
          image: false,
          link: false,
        },
        showtoolbarConfig: {
          moreFormats: false,
          sortAndFilter: false,
          link: false,
          chart: false,
          print: false,
          textRotateMode: false,
          image: false,
          postil: false,
          dataVerification: false,
          splitColumn: false,
          screenshot: false,
          findAndReplace: false,
        },
      };
      luckysheet.create(options);
    } catch (error) {
      // error handling
    }
  };

  document.onkeyup = function (e) {
    if (e && e?.shiftKey) {
      if (e?.ctrlKey && (e?.key === "F" || e?.key === "f" || e?.which === 70)) {   // CTRL + Shift + f Filter Option
        const currentSheetData = luckysheet.getSheet();
        if (currentSheetData?.name === 'Quote Compare') {
          toggleFilterDialog();
        }
      }
    } else if (e?.ctrlKey && (e?.which === 70 || e?.keyCode === 70)) { // CTRL + f Find Option
      toggleFindDialog();
      const propsData = luckysheet?.getSheetData();
      setsheetState(propsData);
    }
  };

  const toggleFindDialog = () => {
    setfindDialog(!findDialog);
  };

  const findDialogClose = (flag) => {
    setfindDialog(flag?.state);
  };

  const toggleFilterDialog = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  const handleFilterDialogClose = (e) => {
    setOpenFilterDialog(false);
    if (e?.filterData?.selectedOption1 && e?.filterData?.selectedOption2) {
      setFilterSelectionData(e?.filterData);
    } else {
      setFilterSelectionData(null);
      dataGrouping(0);
    }
  };

  const getJobFileRecords = async (rId, jobId, bId) => {          /// function to fetch all the three api data for rendering 
    try {
      const [fileResponse, jobData, jobLobQuestionData] = await Promise.all([
        GetJobFiles(rId, bId),
        GetJobQCData(rId, bId),
        GetLobQuestionsByJobId(rId, bId),
      ]);

      return {
        fileResponse: (fileResponse && Array.isArray(fileResponse) && fileResponse.length > 0) ? fileResponse : [],
        jobData,
        jobLobQuestionData,
      };

    } catch (error) {
      console.error("Error fetching job file records:", error);
      container.current.showSnackbar('Error fetching data', "error", true);
      return { fileResponse: [], jobData: null, jobLobQuestionData: null };
    }
  };

  const singleMultipleSwitchInsert = (isInsertBydialog) => {
    let range = luckysheet.getRange()
    let selectRangeRowIndex = range[0].row[0]
    let selectedColRangeIndex = range[0].column[0]
    const hardCodedStartingIndex = [0, 1, 2, 3];
    if (selectedColRangeIndex === 0) {
      const msg = `cannot add rows in the sheetname sections`;
      setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
      setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
      return;
    }
    if ((hasMultipleRowsSelected && hardCodedStartingIndex.includes(selectedRowIndexRange[0])) || hardCodedStartingIndex.includes(selectRangeRowIndex)) {
      const msg = `cannot add rows in the sheetname sections`;
      setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
      setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
      return;
    }
    else {
      const hardCodedStartingIndex = [0];
      if ((hasMultipleRowsSelected && hardCodedStartingIndex.includes(selectedRowIndexRange[0])) || hardCodedStartingIndex.includes(selectRangeRowIndex)) {
        const msg = `cannot add rows in the sheetname sections`;
        setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
        setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
        return;
      }
    }
    let QacFlag = luckysheet.getSheet().name
    let flagCheck = QacFlag;
    if (flagCheck == 'Quote Compare') {
      const isMultiRowSelected = hasMultipleRowsSelected;
      let currentTableRecord = "";
      let TableName = "";
      let tableNameKeys = Object.keys(quoteTableColumnDetails);
      tableNameKeys.forEach((columnName) => {
        if (((quoteTableColumnDetails[columnName]?.range?.start <= selectRangeRowIndex && quoteTableColumnDetails[columnName]?.range?.end >= selectRangeRowIndex) ||
          (isMultiRowSelected && quoteTableColumnDetails[columnName]?.range?.start <= selectedRowIndexRange[0] && quoteTableColumnDetails[columnName]?.range?.end >= selectedRowIndexRange[1])) && Object.keys(quoteTableColumnDetails[columnName]?.columnNames)?.length > 0) {
          currentTableRecord = quoteTableColumnDetails[columnName];
          TableName = columnName;
        }
      });
      if (currentTableRecord?.range && ((isMultiRowSelected && currentTableRecord?.range?.end >= selectedRowIndexRange[0]) || currentTableRecord?.range?.end >= selectRangeRowIndex)) {
        if (TableName) {
          if (TableName != "Table 3" && ((isMultiRowSelected && !(currentTableRecord?.range?.start + 1 < selectedRowIndexRange[0])) || !(currentTableRecord?.range?.start + 1 < selectRangeRowIndex))) {
            const msg = `cannot add rows in the ${TableName} header sections`;
            setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
            setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
            return;
          }
          if (TableName === "Table 3") {
            if ((isMultiRowSelected && !(currentTableRecord?.range?.start + 2 < selectedRowIndexRange[0])) || !(currentTableRecord?.range?.start + 2 < selectRangeRowIndex)) {
              const msg = `cannot add rows in the ${TableName} header sections`;
              setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
              setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
              return;
            }
          }
        } else {
          const msg = `cannot add rows in the ${TableName == "Table 1" ? "Header" : TableName} header sections`;
          setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
          setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
          return;
        }
      }
      if ((quoteTableColumnDetails["Table 1"]?.range?.end == selectRangeRowIndex - 1)) {
        const msg = "Only able to insert rows insde the tables";
        setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
        setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
        return;
      }
      if (((!isMultiRowSelected && quoteTableColumnDetails["Table 2"]?.range?.start < selectRangeRowIndex) ||
        (isMultiRowSelected && quoteTableColumnDetails["Table 2"]?.range?.start < selectedRowIndexRange[0])) && !TableName) {
        const msg = "Only able to insert rows insde the tables";
        setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
        setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
        return;
      }
      if (isMultiRowSelected && selectedRowIndexRange.length > 0) {
        if (isInsertBydialog) {
          setOpenInputDialog(true);
          return;
        }
        const selectedDiff = selectedRowIndexRange[1] - selectedRowIndexRange[0];
        const tableNameKeysBackup = quoteTableColumnDetails;
        luckySheetInsert(selectedRowIndexRange[0], selectedDiff + 1, TableName, luckysheet?.getSheet());
      } else {
        if (isInsertBydialog) {
          setOpenInputDialog(true);
          return;
        }
        luckySheetInsert(selectRangeRowIndex, 1, TableName, luckysheet?.getSheet());
      }
    }
  };

  const luckySheetInsert = (position, noOfRecords, currentTablename, currentSheetData) => {
    let dataToBePopulatedOnInsert = getEmptyDataSet();
    if (currentSheetData?.name === 'Quote Compare') {
      if (position && position > 0 && noOfRecords && noOfRecords > 0) {

        for (let index = 0; index < noOfRecords; index++) {
          luckysheet.insertRow(position);

          if (currentTablename) {
            const currentTableData = quoteTableColumnDetails[currentTablename];

            if (currentTableData && currentTableData?.columnNames) {
              const maxCol = Math.max(...Object.values(currentTableData["columnNames"]));
              const minCol = currentTableData?.columnNames?.ChecklistQuestions || 2;

              if (maxCol > 0) {
                for (let index1 = minCol; index1 < maxCol - 7; index1++) {
                  dataToBePopulatedOnInsert["ct"]["s"][0] = {
                    "v": "",
                    "fc": 'rgb(68, 114, 196)',
                    "fs": "8",
                    "ff": "\"Tahoma\""
                  };

                  luckysheet.setcellvalue(position, index1 + 1, luckysheet.flowdata(), {
                    "v": "",
                    "fc": 'rgb(68, 114, 196)',
                    "fs": "8",
                    "ff": "\"Tahoma\""
                  });
                }
              }
            }
          }
        }

        if (currentTablename) {
          let updateStart = false;
          Object.keys(quoteTableColumnDetails).forEach((table) => {
            let currentTableData = quoteTableColumnDetails[table];

            if (table === currentTablename) {
              currentTableData.range.end += noOfRecords;
              updateStart = true;
            } else if (updateStart) {
              const rangeDiff = noOfRecords;
              currentTableData.range.start += rangeDiff;
              currentTableData.range.end += rangeDiff;
            }

            quoteTableColumnDetails[table] = currentTableData;
          });
          setQuoteTableColumnDetails(quoteTableColumnDetails);
        }
        luckysheet.jfrefreshgrid();
      }
    }
  };

  const insertFnByInputDialog = (noOfRows) => {
    const isMultiRowSelected = true;
    let range = luckysheet.getRange()
    let selectRangeRowIndex = range[0].row[0]
    const selectedDiff = noOfRows;
    let currentTablename = '';
    const currentSheetData = luckysheet?.getSheet();
    if (currentSheetData?.name == 'Quote Compare') {
      const tableNameKeysBackup = quoteTableColumnDetails;
      let tableNameKeys = Object.keys(quoteTableColumnDetails);
      // tableNameKeys = tableNameKeys.filter((f) => f != "Table 1");
      tableNameKeys.forEach((columnName) => {
        if (((tableNameKeysBackup[columnName]?.range?.start > selectRangeRowIndex && tableNameKeysBackup[columnName]?.range?.end >= selectRangeRowIndex)) && Object.keys(tableNameKeysBackup[columnName]?.columnNames)?.length > 0) {
        } else if (((tableNameKeysBackup[columnName]?.range?.start < selectRangeRowIndex && tableNameKeysBackup[columnName]?.range?.end >= selectRangeRowIndex)) &&
          Object.keys(tableNameKeysBackup[columnName]?.columnNames)?.length > 0) {
          currentTablename = columnName;
        }
      });
    }
    luckySheetInsert(selectRangeRowIndex, selectedDiff, currentTablename, currentSheetData);
  };

  const handleInputDialogClose = (e) => {
    if (e?.input && e?.input > 0) {
      insertFnByInputDialog(e?.input);
      setOpenInputDialog(false);
    } else {
      setOpenInputDialog(false);
    }
  };

  const singleMultipleSwitchDelete = () => {
    let range = luckysheet.getRange();
    const selectedRowIndexRange = range[0].row;
    const hardCodedStartingIndex = [0, 1, 2, 3];
    if ((hasMultipleRowsSelected && hardCodedStartingIndex.includes(selectedRowIndexRange[0])) || hardCodedStartingIndex.includes(selectedRowIndexRange[0])) {
      const msg = `cannot delete rows in the sheetname sections`;
      setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
      setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
      return;
    }
    const luckySheet = luckysheet.getSheetData()[1];
    let flagCheck = luckysheet?.getSheet()?.name;
    let sheetData = luckysheet.getSheetData();
    if (flagCheck == 'Quote Compare') {
      const isMultiRowSelected = hasMultipleRowsSelected;
      let currentTableRecord = "";
      let TableName = "";
      let tableNameKeys = Object.keys(quoteTableColumnDetails);
      tableNameKeys.forEach((columnName) => {
        if (((quoteTableColumnDetails[columnName]?.range?.start <= setectedRowIndex && quoteTableColumnDetails[columnName]?.range?.end >= setectedRowIndex) ||
          (isMultiRowSelected && quoteTableColumnDetails[columnName]?.range?.start <= selectedRowIndexRange[0] && quoteTableColumnDetails[columnName]?.range?.end >= selectedRowIndexRange[1])) && Object.keys(quoteTableColumnDetails[columnName]?.columnNames)?.length > 0) {
          currentTableRecord = quoteTableColumnDetails[columnName];
          TableName = columnName;
        }
      });
      if (TableName) {
        if (TableName != "Table 1" && Object.keys(quoteTableColumnDetails[TableName]?.columnNames)?.length > 0) {
          const multipleInsertLength = TableName == "Table 3" ? 2 : 1;
          if (((!isMultiRowSelected && quoteTableColumnDetails[TableName]?.range?.end - (quoteTableColumnDetails[TableName]?.range?.start + multipleInsertLength)) == 1) ||
            isMultiRowSelected && (quoteTableColumnDetails[TableName]?.range?.end - (quoteTableColumnDetails[TableName]?.range?.start + multipleInsertLength) == ((selectedRowIndexRange[1] - selectedRowIndexRange[0]) + 1))) {
            const msg = `the table must have atleast one row/record's`;
            setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
            setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
            return;
          }
          if (!((setectedRowIndex && !isMultiRowSelected && quoteTableColumnDetails[TableName]?.range?.start + multipleInsertLength < setectedRowIndex && quoteTableColumnDetails[TableName]?.range?.end >= setectedRowIndex) ||
            (isMultiRowSelected && quoteTableColumnDetails[TableName]?.range?.start + multipleInsertLength < selectedRowIndexRange[0] && quoteTableColumnDetails[TableName]?.range?.end >= selectedRowIndexRange[1]))) {
            const msg = `cannot delete rows in the ${TableName} header sections and the table must have atleast one row/record's`;
            setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
            setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
            return;
          }
        }
      } else if (!TableName && ((!isMultiRowSelected && quoteTableColumnDetails["Table 1"]?.range?.end < setectedRowIndex) ||
        (isMultiRowSelected && quoteTableColumnDetails["Table 1"]?.range?.end < selectedRowIndexRange[1]))) {
        const msg = `Cannot delete the empty rows are used to separate the table.`;
        setMsgVisible(true); setMsgClass('alert error'); setMsgText(msg);
        setTimeout(() => { setMsgVisible(false); setMsgText(''); }, 3500)
        return;
      }
      if (isMultiRowSelected && selectedRowIndexRange.length > 0) {
        const selectedDiff = selectedRowIndexRange[1] - selectedRowIndexRange[0];

        if (selectedDiff >= 1 && sheetData && sheetData?.length > 0) {
          luckySheetDelete(setectedRowIndex, selectedDiff + 1, TableName);
        }
      } else {
        if (setectedRowIndex != 0 && selectedRowIndexRange.length > 0) {
          const selectedDiff = selectedRowIndexRange[1] - selectedRowIndexRange[0];
          luckySheetDelete(setectedRowIndex, selectedDiff + 1, TableName);
        }
      }
    }


  };

  const luckySheetDelete = (selectedRow, noOfRecords, currentTablename) => {
    const userSelectedRowRange = luckysheet.getRange();
    let noOfRecord = noOfRecords;
    if (userSelectedRowRange && userSelectedRowRange?.length > 0) {
      const continutyArray = [];
      userSelectedRowRange.map((e, index) => {
        if (index === 0) {
          const min = e?.row[0] < e?.row[1] ? e?.row[0] : e?.row[1];
          const max = e?.row[0] > e?.row[1] ? e?.row[0] : e?.row[1];
          if (min && min > 0 && max && max > 0) {
            for (let index = min; index <= max; index++) {
              continutyArray.push(index);
            }
          }
        }
      });
      const uniqueRow = Array.from(new Set(continutyArray));
      const isValidSelection = hasMissingNumbers(uniqueRow);
      if (uniqueRow && uniqueRow?.length > 0 && isValidSelection === false) {
        luckysheet.deleteRow(uniqueRow[0], uniqueRow[uniqueRow?.length - 1]);
        luckysheet.setRangeShow({ row: [uniqueRow[0], uniqueRow[0]], column: [1, 1] });
      }
    }
    if (currentTablename) {
      let updateStart = false;
      Object.keys(quoteTableColumnDetails).forEach((table) => {
        let currentTableData = quoteTableColumnDetails[table];

        if (table === currentTablename) {
          currentTableData.range.end -= noOfRecord;
          updateStart = true;
        } else if (updateStart) {
          const rangeDiff = noOfRecord;
          currentTableData.range.start -= rangeDiff;
          currentTableData.range.end -= rangeDiff;
        }

        quoteTableColumnDetails[table] = currentTableData;
      });
      setQuoteTableColumnDetails(quoteTableColumnDetails);
    }
  };

  const hasMissingNumbers = (arr) => {
    arr.sort((a, b) => a - b);
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] !== arr[i - 1] + 1) {
        return true;
      }
    }
    return false;
  };

  const handleIconClick = () => {
    const currentSheetData = luckysheet.getSheet();
    if (currentSheetData?.name === 'Quote Compare') {
      toggleFilterDialog();
    }
  };


  return (
    <div className="main-container">
      <div className="app-container">
        <div style={{ padding: "5px" }}>
          {msgVisible &&
            <div className="alert-container">
              <div className={msgClass}>{msgText}</div>
            </div>
          }
          <div>
            <PrimaryButton className="luckySheet_header_button" onClick={() => updateAndExportCall(true)}>Save</PrimaryButton>
            <PrimaryButton className="luckySheet_header_button" onClick={() => updateAndExportCall(false)}>Generate Checklist</PrimaryButton>
            <PrimaryButton className="luckySheet_header_button" onClick={() => singleMultipleSwitchInsert(true)}>Insert Row</PrimaryButton>
            <PrimaryButton className="luckySheet_header_button" onClick={() => singleMultipleSwitchDelete()}>Delete Row</PrimaryButton>
          </div>
          {openInputDialog && <InputDialogComponent isOpen={openInputDialog} onClose={(e) => handleInputDialogClose(e)} />}
          {findDialog && <FindDialogComponent isOpen={findDialog} luckySheet={luckysheet} sheetState={sheetState} onClose={(e) => findDialogClose(e)} message={msgText} />}
          {openFilterDialog && <FilterQcDialogComponent isOpen={{ openFilterDialog, quoteTableColumnDetails, dataSet, luckysheet, filterSelectionData }} onClose={(e) => handleFilterDialogClose(e)} />}
          <div style={{ position: "relative" }}>

            <Icon iconName="Filter"
              onClick={handleIconClick}
              style={{
                position: 'absolute',
                top: '2px',
                left: '1090px',
                fontSize: '16.2px',
                margin: '5px',
                zIndex: 10,
                cursor: 'pointer'
              }} />

            <h6 style={{
              position: 'absolute',
              top: '3px',
              fontWeight: 500,
              left: '1110px',
              fontSize: '12.5px',
              margin: '5px',
              zIndex: 10,
              cursor: 'pointer'
            }}
              onClick={handleIconClick}
            >Filter</h6>


            <div className="QuoteApp" id="luckysheet" ref={luckyCss}></div>
          </div>
        </div>
      </div>
      <SimpleSnackbar ref={container} />
    </div>
  );
};
export default QCDataRenderer;
