import { Icon, Stack } from "@fluentui/react";
import { IconButton } from "@fluentui/react/lib/Button";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initializeIcons } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";

initializeIcons();

export const AppSidebar = ({ selectedOption }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [canReportVisible, setCanReportVisible] = useState(false);
  const [canAdminReportVisible, setCanAdminReportVisible] = useState(false);
  const [canDeletionReportVisible, setCanDeletionReportVisible] = useState(false);
  const [selectedOptionn, setSelectedOptionn] = useState(null);
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const location = useLocation();
  const showSidebar = location.pathname !== "/csrView";

  useEffect(() => {
    async function fetchData() {
      const url = new URL(window.location.href);
      const userName =
        sessionStorage.getItem("userName") || url?.searchParams.get("user");
      await GetAllRoles(userName);
    }
    fetchData();
    setSelectedOptionn(location.pathname.replace("/", ""));
  }, [location]);

  const GetAllRoles = async (UserName) => {
    if (UserName) {
      document.body.classList.add("loading-indicator");
      var token = sessionStorage.getItem("token");
      const updatedToken = await processAndUpdateToken(token);
      const headers = {
        Authorization: `Bearer ${updatedToken}`,
        "Content-Type": "application/json",
      };
      try {
        const response = await axios.get(
          baseUrl + "/api/Defaultdatum/GetUserRole?userName=" + `${UserName}`,
          { headers }
        );
        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const Role = response?.data?.Role == null || undefined ? [] : response?.data?.Role;
        const Roles = response?.data?.Roles == null || undefined ? [] : response?.data?.Roles;


        if (Role.includes('report')) {
          setCanAdminReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanAdminReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
        if (Roles.includes('CsrReport')) {
          setCanReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
        if (Role.includes('DeletionReport')) {
          setCanDeletionReportVisible(true)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        } else {
          setCanDeletionReportVisible(false)
          sessionStorage.setItem('role', response?.data?.Role);
          sessionStorage.setItem('roles', response?.data?.Roles);
        }
      } catch (error) {
        setMsgVisible(true);
        setMsgClass("alert error");
        setMsgText(`Error fetching data: ${error}`);
        setTimeout(() => {
          setMsgVisible(false);
          setMsgText("");
        }, 3500);
      } finally {
        document.body.classList.remove("loading-indicator");
      }
    }
  };

  const handleMouseEnter = () => {
    setIsSidebarOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarOpen(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const renderSidebarButton = (item) => (
    <Link to={`/${item.key}`} key={item.key} className="sidebar-link">
      <Stack
        horizontal
        className={`sidebar-item ${location.pathname === `/${item.key}` ? "selected" : ""}`}
      >
        <Stack.Item>
          <Icon iconName={item.iconProps.iconName} className="sidebar-icon" />
        </Stack.Item>
        {isSidebarOpen && <Stack.Item className="sidebar-text">{item.name}</Stack.Item>}
      </Stack>
    </Link>
  );

  const primaryItems = selectedOption === "PolicyCheck"
    ? [
      { key: "dashboard", name: "Home", iconProps: { iconName: "Home" } },
      { key: "job", name: "My Jobs", iconProps: { iconName: "FabricUserFolder" } },
      { key: "AllJob", name: "All Jobs", iconProps: { iconName: "Group" } },
      { key: "checkListError", name: "CheckList Error", iconProps: { iconName: "ReleaseGateError" } },
      ...(canAdminReportVisible
        ? [{ key: "AdminAccess", name: "Admin Access", iconProps: { iconName: "Admin" } }]
        : []),
      ...(canReportVisible
        ? [{ key: "CSRReportPanel", name: "CSR Report", iconProps: { iconName: "CRMReport" } }]
        : []),
      // ...
      // [{ key: "Discrepancy ", name: "Discrepancy ", iconProps: { iconName: "SplitObject" } }]
      //   ,
      ...(canDeletionReportVisible
        ? [{ key: "DeletionReportPanel", name: "Deletion Report Panel", iconProps: { iconName: "TextDocument" } }]
        : []),
    ]
    : selectedOption === "QuoteCompare"
      ? [
        { key: "quoteCompare", name: "Quote Compare", iconProps: { iconName: "FabricUserFolder" } },
      ]
      : [];

  const sidebarItems = [
    ...primaryItems,
  ];

  return showSidebar ? (
    <div
      className={`sidebar-container ${isSidebarOpen ? "open" : "collapsed"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-header">
        <IconButton
          iconProps={{ iconName: "CollapseMenu" }}
          title="Toggle Sidebar"
          ariaLabel="Toggle Sidebar"
          onClick={handleToggleSidebar}
          className="sidebar-toggle-button"
        />
      </div>

      {sidebarItems.map(renderSidebarButton)}
    </div>
  ) : null;
};
