import React, { useState } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { ReportExcelExport } from "./ExportExcel/ReportExcelExport";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ErrorLogSection = (props) => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);
  const [alignment, setAlignment] = useState("LogReport");

  const column = [
    {
      name: "S.No",
      selector: (row, index) => index,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "100px",
    },
    {
      name: "Job Id",
      cell: (row) => row?.JobId,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "200px",
    },
    {
      name: "Message Logged On",
      cell: (row) => row?.CreatedOn,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "180px",
    },
    {
      name: "User",
      cell: (row) => row?.CreatedBy,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "180px",
    },
    {
      name: "Process Name",
      cell: (row) => row?.ProcessName,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Message",
      cell: (row) => {
        if (row.Message == null) {
          return row?.ProcessName;
        } else {
          return (
            <span title={row.Message}>
              {row.Message.length > 70
                ? `${row.Message.substring(0, 60)}...`
                : row.Message}
            </span>
          );
        }
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
  ];

  const getData = async () => {
    if (searchText && searchText?.length > 0) {
      let token = sessionStorage.getItem("token");
      document.body.classList.add("loading-indicator");
      token = await processAndUpdateToken(token);
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        let response = {};
        if (alignment === "LogReport") {
          response = await axios.get(
            baseUrl + "/api/Defaultdatum/GetJobAuditLogs?jobId=" + searchText,
            {
              headers,
            }
          );
        } else {
          response = await axios.get(
            baseUrl + "/api/Defaultdatum/GetUserActionLogs?jobId=" + searchText,
            {
              headers,
            }
          );
        }
        document.body.classList.remove("loading-indicator");
        if (response.status !== 200) {
          return "failed";
        }
        if (response?.data) {
          response.data = response.data.map((e) => {
            if (e["CreatedOn"]) {
              e["CreatedOn"] = formatTo12HourIST(e["CreatedOn"]);
            }
            return e;
          });
          setJobInstance(response.data);
        } else {
          return "failed";
        }
      } catch (error) {
        document.body.classList.remove("loading-indicator");
        return "failed";
      }
    }
  };

  const formatTo12HourIST = (date) => {
    // Adjust the date to IST (UTC+5:30)
    date = new Date(date);
    // const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const istDate = new Date(date.getTime());

    // Get the date components
    const year = istDate.getFullYear();
    const month = (istDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const day = istDate.getDate().toString().padStart(2, "0");

    // Get the hours, minutes, and seconds
    let hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();

    // Determine AM/PM suffix
    const period = hours >= 12 ? "PM" : "AM";

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'

    // Format the date & time
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${period}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const exportData = () => {
    if (jobInstance && jobInstance?.length > 0) {
      const dataForExport = [{ data: jobInstance }];
      const fileName = jobInstance[0]?.JobId + ` ${alignment}`;
      ReportExcelExport(dataForExport, fileName);
    }
  };

  return (
    <div style={{ width: "100%", padding: "0px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "start" }}>
          <TextField
            placeholder="Search JobId..."
            value={searchText}
            onChange={(e, newValue) => {
              setSearchText(newValue);
            }}
            style={{
              width: "250px",
              marginRight: "8px",
            }}
          />
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
            }
            onClick={() => {
              getData();
            }}
            style={{
              marginLeft: "-50px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
          <ToggleButtonGroup
            color="primary"
            size="small"
            style={{ marginLeft: "5px" }}
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="LogReport">Log Report</ToggleButton>
            <ToggleButton value="UserActionReport">
              User Action Report
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <PrimaryButton
            text={
              <span style={{ color: "white", fontWeight: "bold" }}>Export</span>
            }
            onClick={() => {
              exportData();
            }}
            style={{
              marginLeft: "-50px",
              height: "32.1px",
              fontSize: "10px",
            }}
          />
        </div>
      </div>

      <br />
      <div>
        <CommonDataTable column={column} data={jobInstance} />
      </div>
    </div>
  );
};

export default ErrorLogSection;
