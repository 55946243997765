import React, { useState, useEffect } from "react";
import axios from "axios";
import { PrimaryButton, DefaultButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { updateGridAuditLog } from '../Services/PreviewChecklistDataService';
import {auditProcessNames} from '../Services/enums';



const CsrReadyJobs = () => {
  const [data, SetData] = useState([]);

  const column = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
      style: { fontSize: "11px !important" },
      width: "300px",
    },
    {
        name: "Status",
        selector: (row) => {
          const status = row?.Status;
          const color =
            status === "Processed"
              ? "lightGreen"
              : status === "Pending"
              ? "royalBlue"
              : status === "Failed"
              ? "red"
              : "black";
          return <div style={{ color }}>{status}</div>;
        },
        sortable: true,
        style: { fontSize: "11px !important" },
      },
    {
      name: "User",
      selector: (row) => {
        if (!row?.User) return "";
        const UserName = row.User.split("@")[0];
        return UserName.charAt(0).toUpperCase() + UserName.slice(1);
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
        name: "Export Excel",
        cell: (row) =>
          row?.Status === "Processed" ? (
            <DefaultButton
              onClick={(e) => {
                exportData(row);
              }}
              text={
                <span
                  style={{ color: "black", fontSize: "10px", fontWeight: "bold" }}
                >
                  Export
                </span>
              }
            />
          ) : null,
        style: { fontSize: "11px !important" },
      },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let response = await axios.get(
        baseUrl + "/api/ProcedureData/grid-CsrReadyJobs",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      SetData(response.data);
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };


  const handleButtonClick = async (userName, Process) => {
    let token = sessionStorage.getItem( "token" );
    document.body.classList.add('loading-indicator');
    const Token = await processAndUpdateToken(token);
    updateGridAuditLog("Csr-ReadyJobs-Update-initiated");
    const headers = {
        'Authorization': `Bearer ${Token}`,
        "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/ProcedureData/CsrReadyJob`;
    try {
        const response = await axios({
            method: "POST",
            url: apiUrl,
            headers: headers,
            data: {
                UserName: userName,
                Process: Process,
                Status: 'Pending'
            }
        });
        if (response.status !== 200) {
            updateGridAuditLog("Csr-ReadyJobs-Update-failed",JSON.parse(response));
            return "error";
        }
        return response.data;
    } catch (error) {
        updateGridAuditLog("Csr-ReadyJobs-Update-failed",error);
        return "error";
    } finally {
        updateGridAuditLog("Csr-ReadyJobs-Update-completed","");
        document.body.classList.remove('loading-indicator');
        return "success";
    }
};

const exportData = async (row) => {
    const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const apiUrl = `${baseUrl}/api/Excel/CsrExportReadyJobsDownloads/CsrExportReadyJobsExportExcel?Id=` + row?.Id;

    try {
      const response = await axios({
        method: "Get",
        url: apiUrl,
        headers: headers,
        responseType: "blob",
      });
      if (response.status !== 200) {
        return "error";
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `CsrReadyJobs_${new Date().toLocaleDateString()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      return "success";
    } catch (error) {
      console.error("Error:", error);
      return "error";
    }
  };

  return (
    <div style={{ width: "100%", paddingTop: "5px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "start" }}>
         
        <PrimaryButton
              onClick={() => handleButtonClick(sessionStorage.getItem("userName"), auditProcessNames?.CsrReadyJob )}
            >
              Generate
            </PrimaryButton>
        </div>
      </div>
      <br />
      <div>
        <CommonDataTable
          key={"jData"}
          column={column}
          data={data}
        />
      </div>
    </div>
  );
};
export default CsrReadyJobs;
