import React, {  useState } from "react";
import { Tabs, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import ReportSection from "./ReportSection";


const DeletionReport = (props) => {
  const [msgVisible, setMsgVisible] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [activeTab, setActiveTab] = useState("table3");
  const [role, setRole] = useState(sessionStorage.getItem("role"));

  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {role.includes('DeletionReport')? (
         <div>
         <div style={{ width: "100%" }}>
           <div className="app-container">
             <div style={{ padding: "5px", width: "95%" }}>
               {msgVisible && (
                 <div className="alert-container">
                   <div className={msgClass}>{msgText}</div>
                 </div>
               )}
               <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                 <Tabs
                   value={activeTab}
                   onChange={handleTabChange}
                   variant="scrollable"
                   scrollButtons
                   allowScrollButtonsMobile
                 >
                  
                   <Tab
                     label="DELETION REPORT"
                     value="table3"
                     style={{ backgroundColor: "white !important" }}
                   />
                  
                 </Tabs>
               </Box>
               <div>
                 {activeTab == "table3" && <ReportSection activeTab={activeTab} />}
               </div>
             </div>
           </div>
         </div>
       </div>
      ) :''}
    </>
  );
};

export default DeletionReport;
