import React, { useEffect, useState } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Grid } from "@mui/material";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { ReportExcelExport } from "./ExportExcel/ReportExcelExport";

const ReProcessSection = (props) => {
  const [searchText, setSearchText] = useState("");
  const [jobInstance, setJobInstance] = useState([]);
  const [jobInstanceFiltered, setJobInstanceFiltered] = useState([]);

  useEffect(() => {
    getData();
  }, [props]);

  const column = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Job Id",
      selector: (row) => row?.Jobid,
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "CreatedOn",
      selector: (row) => {
        if (row.CreatedOn != null && row.CreatedOn != undefined) {
          const createdDate = new Date(row.CreatedOn).toISOString();
          return createdDate;
        } else {
          return "";
        }
      },
      sortable: true,
      style: { fontSize: "11px !important" },
    },
    {
      name: "Action",
      cell: (row) => (
        <DefaultButton
          className="ref_button"
          text={
            <span
              style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}
            >
              Reprocess
            </span>
          }
          styles={{
            rootHovered: {
              backgroundColor: "#251ca8",
            },
          }}
          onClick={() => ReprocessGridJob(row)}
        />
      ),
      style: { fontSize: "11px !important" },
    },
  ];

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        baseUrl + "/api/Defaultdatum/GetallJobinstance",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        setJobInstance(response.data);
        setJobInstanceFiltered([]);
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };

  const ReprocessGridJob = async (row) => {
    if (row.Jobid) {
      document.body.classList.add("loading-indicator");
      let token = sessionStorage.getItem("token");
      const updatedToken = await processAndUpdateToken(token);
      const headers = {
        Authorization: `Bearer ${updatedToken}`,
        "Content-Type": "application/json",
      };
      try {
        const jobId = row.Jobid;
        const extent = jobId ? `?jobId=${jobId}` : "";
        const URL = `${baseUrl}/api/CheckList/ReprocessGridJob${extent}`;
        const response = await axios.post(URL, null, { headers });
        if (response.status !== 200 || response.status !== 201) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        getData();
      } catch (error) {
        setJobInstance([]);
      } finally {
        document.body.classList.remove("loading-indicator");
      }
    }
  };

  const filterData = () => {
    if (searchText && searchText?.length > 0) {
      setJobInstanceFiltered(
        jobInstance.filter((f) =>
          f?.Jobid?.trim()
            ?.toLowerCase()
            ?.includes(searchText?.trim()?.toLowerCase())
        )
      );
    } else {
      setJobInstanceFiltered([]);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <TextField
          placeholder="Search JobId..."
          value={searchText}
          onChange={(e, newValue) => {
            setSearchText(newValue);
          }}
          style={{
            width: "250px",
            marginRight: "8px",
          }}
        />
        <PrimaryButton
          text={
            <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
          }
          onClick={() => {
            filterData();
          }}
          style={{
            marginLeft: "-50px",
            height: "32.1px",
            fontSize: "10px",
          }}
        />
      </div>
      <br />
      <div>
        <CommonDataTable
          column={column}
          data={
            jobInstanceFiltered?.length > 0 ? jobInstanceFiltered : jobInstance
          }
        />
      </div>
    </div>
  );
};
export default ReProcessSection;
